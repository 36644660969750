import { Box } from '@mui/material';
import NavigationBar from 'containers/OutletContainer';

import DebugSnackbar from 'components/DebugSnackbar';
import { VersionInfo } from 'components/VersionInfo/VersionInfo';
import { BrandProvider } from 'context/BrandContext';
import AuthWrapper from 'wrappers/AuthWrapper';
import ModalManagerWrapper from 'wrappers/ModalManagerWrapper';
import { ProxyProductDebugTool } from './ProxyProductDebugTool';
import RunningOnStageWarning from './RunningOnStageWarning';
import MSALDecider from 'wrappers/MSALDecider';
import LilyTokenFetcher from 'wrappers/LilyTokenFetcher';

const INNER_BOX_STYLES = { background: '#FAFAFA', minHeight: '100vh', width: '100%' };

const App = () => (
    <MSALDecider>
        <LilyTokenFetcher>
            <BrandProvider>
                <RunningOnStageWarning />
                <ProxyProductDebugTool />
                <AuthWrapper>
                    <DebugSnackbar />
                    <Box sx={INNER_BOX_STYLES}>
                        <NavigationBar />
                    </Box>
                </AuthWrapper>
                <VersionInfo />
                <ModalManagerWrapper />
            </BrandProvider>
        </LilyTokenFetcher>
    </MSALDecider>
);

export default App;
