import { EvaluationDetails, FlagValue, Hook, HookContext, OpenFeature } from '@openfeature/web-sdk';
import useBrand from 'helpers/useBrand';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PageLoader from 'components/common/PageLoader';
import { encryptLocalData } from 'helpers/EncryptLocalStorage';

interface TFeatureFlagGuard {
    Component: (props: any) => JSX.Element;
    permissionNeeded: string;
    RedirectComponent?: (props: any) => JSX.Element;
}

const FeatureFlagGuard = ({ Component, permissionNeeded, RedirectComponent = undefined }: TFeatureFlagGuard) => {
    const [flag, setFlag] = useState<EvaluationDetails<string>>();
    const [refreshCount, setCount] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const currentLocation = location.pathname;
    const {
        brandsContext: { currentRetailer },
    } = useBrand();
    class FlagHook implements Hook {
        error(hookContext: Readonly<HookContext<FlagValue>>, error: unknown): void {
            console.error(error, hookContext);
            setFlag(undefined);
        }
    }

    const flagStatus = useCallback(() => {
        const client = OpenFeature.getClient();
        const routeDetails = client.getStringDetails(permissionNeeded, 'Invisible', { hooks: [new FlagHook()] });
        setFlag(routeDetails);
        return routeDetails;
    }, [permissionNeeded]);

    useEffect(() => {
        //resolves async issue. Flag state sets after useEffect check.
        const currentFlag = flagStatus();
        //BAND-AID FIX TO RESOLVE LAUNCH DARKLY DOUBLE STREAMING BUG
        const newCount = refreshCount + 1;
        setCount(newCount);
        if (currentFlag?.value !== 'Clickable' && !RedirectComponent && currentLocation !== '/attributes' && newCount > 2) {
            encryptLocalData('index', 0);
            navigate('/attributes');
        }

        //adding flag causing a app to redirect to attribute while checking current flag
    }, [currentRetailer, currentLocation]);

    if (currentRetailer.code === '' || flag?.reason === 'ERROR') {
        return <PageLoader />;
    }

    return flag && flag.value === 'Clickable' ? <Component /> : RedirectComponent ? <RedirectComponent /> : null;
};

export default FeatureFlagGuard;
