import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import noPreviewImage from 'assets/images/nopreview.jpeg';
import { MODAL_TYPES } from 'constants/modalTypes';
import { Modal as ModalType } from 'models/modals';

import { closeModal } from 'redux-state/modals/actions';

const ModalBox: typeof Box = styled(Box)(() => ({
    background: 'white',
    border: '1px solid #E2DFDF',
    borderRadius: '4px',
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    height: '70vh',
    left: '50%',
    maxHeight: '485px',
    outline: 'none',
    padding: '15px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
}));

const InnerModalContainer: typeof Stack = styled(Stack)(() => ({
    height: '55vh',
    marginTop: '20px',
    maxHeight: '424px',
    overflow: 'hidden',
}));

const ImagesScrollList: typeof Box = styled(Box)(() => ({
    '&::-webkit-scrollbar': { display: 'none' },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    overflow: 'scroll',
}));

const ProductImageForSlider: typeof Box = styled(Box)(() => ({
    borderRadius: '5px',
    cursor: 'pointer',
    height: '127px',
    objectFit: 'cover',
    userSelect: 'none',
    width: '127px',
}));

const SelectedProductImage: typeof Box = styled(Box)(() => ({
    borderRadius: '5px',
    height: '55vh',
    maxHeight: '424px',
    maxWidth: '424px',
    objectFit: 'cover',
    userSelect: 'none',
    width: '55vh',
}));

const CloseButton = styled(IconButton)(() => ({
    color: '#81868a',
    cursor: 'pointer',
    padding: 0,
    position: 'absolute',
    right: '7.5px',
    top: '7.5px',
}));

const ImagesGalleryModal = ({ modal }: { modal: ModalType }) => {
    const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState<number>(modal?.props?.imageIndex || 0);

    const handleOnError = useCallback((e: any) => {
        e.target.src = noPreviewImage;
    }, []);

    const handleClose = useCallback(() => {
        dispatch(closeModal(MODAL_TYPES.PRODUCT_IMAGES));
    }, []);

    const handleImageClick = useCallback((index: number) => () => setSelectedIndex(index), []);

    return (
        <Modal open={true}>
            <ModalBox>
                <CloseButton disableFocusRipple disableRipple onClick={handleClose}>
                    <CancelRoundedIcon fontSize="small" />
                </CloseButton>
                <InnerModalContainer direction="row" gap="12px">
                    <ImagesScrollList>
                        <Stack direction="column" gap="12px">
                            {modal?.props?.imagesList.map((src: string, index: number) => (
                                <ProductImageForSlider
                                    alt="productImage"
                                    component="img"
                                    key={`${index}_${src}`}
                                    loading="lazy"
                                    onClick={handleImageClick(index)}
                                    onError={handleOnError}
                                    src={src || noPreviewImage}
                                />
                            ))}
                        </Stack>
                    </ImagesScrollList>
                    <SelectedProductImage
                        alt="productImage"
                        component="img"
                        loading="lazy"
                        onError={handleOnError}
                        src={(modal?.props?.imagesList || [])[selectedIndex] || noPreviewImage}
                    />
                </InnerModalContainer>
            </ModalBox>
        </Modal>
    );
};

export default ImagesGalleryModal;
