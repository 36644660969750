import { Box, Container, Paper } from '@mui/material';
import BlueWave from 'assets/images/blue-wave';
import LoginBorderStyles from './Styles/LoginBorderStyles';

const LoginBorder = ({ children }: { children: React.ReactElement<any, any> }) => {
    const classes = LoginBorderStyles();
    return (
        <Container className={classes.wholeScreenCont} maxWidth="xl">
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    zIndex: 1,
                }}
            >
                <BlueWave />
            </Box>
            <Paper
                elevation={0}
                sx={{
                    borderRadius: '10px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10,
                }}
            >
                {children}
            </Paper>
        </Container>
    );
};
export default LoginBorder;
