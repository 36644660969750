import { AxiosResponse } from 'axios';
import sliceDebugSnackbar from './reducers';
import { AppThunk } from 'redux-state/store';

export const openDebugSnackbar =
    (message: AxiosResponse<any, any>): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(sliceDebugSnackbar.actions.openSnackbar(message));
    };

export const closeDebugSnackbar =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(sliceDebugSnackbar.actions.removeSnackbar());
    };
