import Cookies from 'js-cookie';
import { LilyJWT } from 'models/auth/login';
import { decodeToken } from 'react-jwt';

export const lilyJwt = (): LilyJWT => {
    const token: string | undefined = Cookies.get('lilytoken') as string;
    if (!token) {
        return new LilyJWT();
    }
    const decodedToken = decodeToken(token);
    return decodedToken as LilyJWT;
};

export const parseJwt = (token: string): LilyJWT => {
    if (!token) {
        return new LilyJWT();
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
};

//TODO: Create fall through for various roles as they are added.
// ADMIN can access WRITE can access READ
export const permissionChecker = (role: string): boolean => {
    const user = lilyJwt();

    if (!user) {
        return false;
    }

    switch (user.role) {
        case 'LILY ADMIN':
            return true;
        case role:
            return true;
        default:
            return false;
    }
};
