import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { filter } from 'lodash';
import { Modal } from 'models/modals';

const initialState: Modal[] = [];

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal(state: Modal[], action: PayloadAction<Modal>): void {
            state.push(action.payload);
        },
        closeModal(state: Modal[], action: PayloadAction<string>): void {
            state.splice(
                state.findIndex((modal) => modal.id === action.payload),
                1
            );
        },
    },
});

export const { reducer } = modalSlice;

export default modalSlice;
