import { AllupdatedQueryProducts, Batch, BatchName, BatchStatus, ProxyProductRedux } from 'models/proxyProduct/ProxyProduct';
import { cloneDeep, size, union } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { encryptLocalData, getEncyptedLocalData } from 'helpers/EncryptLocalStorage';

const initialState: ProxyProductRedux = {
    allupdatedQueryProducts: new AllupdatedQueryProducts(),
    amountOfPages: 1,
    batch: new BatchName(),
    batches: [],
    batchPaginated: [],
    batchStatus: new BatchStatus(),
    currentPage: 1,
    edited: [],
    editingHero: null,
    editPercent: 0,
    loading: true,
    perPage: 10,
    updatedHero: null,
    viewed: [],
    viewedPercent: 0,
};

const sliceProxyProduct = createSlice({
    name: 'Timeline',
    initialState,
    reducers: {
        initializeState(state: ProxyProductRedux) {
            state = initialState;
        },
        setPage(state: ProxyProductRedux, action: PayloadAction<number>): void {
            state.currentPage = action.payload;
        },
        setPerPage(state: ProxyProductRedux, action: PayloadAction<number>): void {
            const amountPerPage = action.payload;
            state.perPage = amountPerPage;
            state.currentPage = 1;
            const totalItems = state.batchStatus.totalQueryProductsCount;
            if (totalItems > amountPerPage) {
                state.amountOfPages = Math.ceil(totalItems / amountPerPage);
            } else {
                state.amountOfPages = 1;
            }
        },
        setCount(state: ProxyProductRedux, action: PayloadAction<number>): void {
            state.amountOfPages = action.payload;
        },
        setLoading(state: ProxyProductRedux, action: PayloadAction<boolean>): void {
            state.loading = action.payload;
        },
        setBatches(state: ProxyProductRedux, action: PayloadAction<BatchName[]>): void {
            //reset selection
            if (action.payload?.some((batchName: BatchName) => batchName.batchID === state.batch.batchID)) return;
            state.batches = action.payload;

            state.allupdatedQueryProducts = { batchID: '', batch: [] };
            state.amountOfPages = 1;
            state.batch = new BatchName();
            state.batchPaginated = [];
            state.batchStatus = new BatchStatus();
            state.currentPage = 1;
            state.edited = [];
            state.editingHero = null;
            state.editPercent = 0;
            state.loading = false;
            state.perPage = 10;
            state.updatedHero = null;
            state.viewed = [];
            state.viewedPercent = 0;
        },
        updatePaginatedBatch(state: ProxyProductRedux, action: PayloadAction<Batch[]>): void {
            state.batchPaginated = action.payload;
        },
        ClearSelectedBatch(state: ProxyProductRedux) {
            state.allupdatedQueryProducts = { batchID: '', batch: [] };
            state.amountOfPages = 1;
            state.batch = new BatchName();
            state.batchPaginated = [];
            state.batchStatus = new BatchStatus();
            state.currentPage = 1;
            state.edited = [];
            state.editingHero = null;
            state.editPercent = 0;
            state.loading = false;
            state.perPage = 10;
            state.updatedHero = null;
            state.viewed = [];
            state.viewedPercent = 0;
        },

        setSelectedBatch(state: ProxyProductRedux, action: PayloadAction<{ batch: BatchName; batchStatus: BatchStatus; data: Batch[] }>) {
            const { batch, batchStatus, data } = action.payload;
            // only update with new batch
            if (batch.batchID !== state.batch.batchID) {
                // reset realtive values
                state.batch = batch;
                state.batchPaginated = data;
                state.perPage = batchStatus.totalQueryProductsCount < 25 ? batchStatus.totalQueryProductsCount : 25;
                state.amountOfPages = Math.ceil(batchStatus.totalQueryProductsCount / state.perPage);
                state.batchStatus = batchStatus;

                // check for saved info
                const saved = getEncyptedLocalData(`viewed_batch_${state.batch.batchID}`);
                if (size(saved) > 0) {
                    state.viewed = saved.viewed;
                    state.viewedPercent = saved.viewedPercent;
                    state.edited = saved.edited;
                    state.editPercent = saved.editPercent;
                    state.allupdatedQueryProducts = saved.allupdatedQueryProducts;
                    state.currentPage = saved.currentPage;
                    state.perPage = saved.perPage;
                    state.amountOfPages = saved.amountOfPages;
                } else {
                    state.viewedPercent = 0;
                    state.viewed = [];
                    state.edited = [];
                    state.editPercent = 0;
                    state.allupdatedQueryProducts = { batchID: batch.batchID, batch: [] };
                    state.perPage = batchStatus.totalQueryProductsCount < 25 ? batchStatus.totalQueryProductsCount : 25;
                    state.amountOfPages = Math.ceil(batchStatus.totalQueryProductsCount / state.perPage);
                }
            }
        },
        setUpdatedHero(state: ProxyProductRedux, action: PayloadAction<Batch>): void {
            state.updatedHero = action.payload;
        },
        setEditingHero(state: ProxyProductRedux, action: PayloadAction<Batch>): void {
            state.editingHero = action.payload;
        },
        updateEditedList(state: ProxyProductRedux) {
            const heroClone = cloneDeep(state.updatedHero) as Batch;
            const allupdatedClone = cloneDeep(state.allupdatedQueryProducts);
            if (!Array.isArray(allupdatedClone.batch)) allupdatedClone.batch = [];
            allupdatedClone.batch = allupdatedClone.batch.filter((batch) => batch.queryProduct !== heroClone.queryProduct);
            allupdatedClone.batch.push(heroClone);
            const editedClone = cloneDeep(state.edited);
            const updatedList = union(editedClone, [heroClone.queryProduct]);
            state.editingHero = null;
            state.edited = updatedList;
            state.allupdatedQueryProducts = allupdatedClone;
            state.editPercent = Math.round((size(updatedList) * 100) / state.batchStatus.totalQueryProductsCount);
        },
        updateViewed(state: ProxyProductRedux, action: PayloadAction<string>): void {
            const clone = cloneDeep(state.viewed);
            const updatedList = union(clone, [action.payload]);
            state.viewed = updatedList;
            state.viewedPercent = Math.round((size(updatedList) * 100) / state.batchStatus.totalQueryProductsCount);
        },
        saveViewed(state: ProxyProductRedux) {
            encryptLocalData(`viewed_batch_${state.batch.batchID}`, {
                viewed: state.viewed,
                viewedPercent: state.viewedPercent,
                edited: state.edited,
                editPercent: state.editPercent,
                allupdatedQueryProducts: state.allupdatedQueryProducts,
                currentPage: state.currentPage,
                perPage: state.perPage,
                amountOfPages: state.amountOfPages,
            });
        },
    },
});

export const { reducer } = sliceProxyProduct;

export default sliceProxyProduct;
