import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { DebugSnackbar } from 'models/debugSnackbar/debugSnackbar';

const initialState: DebugSnackbar = {
    open: false,
    message: undefined,
};

const sliceDebugSnackbar = createSlice({
    name: 'DebugSnackbar',
    initialState,
    reducers: {
        openSnackbar(state: DebugSnackbar, action: PayloadAction<AxiosResponse<any, any>>): void {
            state.open = true;
            state.message = action.payload;
        },
        removeSnackbar(state) {
            state.open = false;
            state.message = undefined;
        },
    },
});

export const { reducer } = sliceDebugSnackbar;

export default sliceDebugSnackbar;
