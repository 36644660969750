import { createSlice } from '@reduxjs/toolkit';
import { PAMProductDetail } from 'models/PAM/ProductDetail';
import { UPDATE_TAG_RESPONSE_INFOS } from './constants';

const initialState: PAMProductDetail = {
    updateTagsInProgress: false,
    updateTagsSucceeded: false,
    updateTagsFailed: false,
    updateTagsResponse: '',
    updateTagsSeverity: '',
};

//can be removed now?
const sliceProductEdits = createSlice({
    name: 'productCopyProductDetails',
    initialState,
    reducers: {
        updateTagsRequest(_state, _action) {
            return {
                updateTagsInProgress: true,
                updateTagsSucceeded: false,
                updateTagsFailed: false,
                updateTagsResponse: '',
                updateTagsSeverity: '',
            };
        },
        updateTagsSuccess(_state, _action) {
            return {
                updateTagsInProgress: false,
                updateTagsSucceeded: true,
                updateTagsFailed: false,
                updateTagsResponse: 'Tag updated successfully! Your changes will affect all product variants!',
                updateTagsSeverity: 'info',
            };
        },
        updateTagsFailure(_state, _action) {
            return {
                updateTagsInProgress: false,
                updateTagsSucceeded: false,
                updateTagsFailed: true,
                updateTagsResponse: UPDATE_TAG_RESPONSE_INFOS.FAILED_TO_SAVE,
                updateTagsSeverity: 'error',
            };
        },
        updateTagsFlush(_state, _action) {
            return initialState;
        },
    },
});

export const { reducer } = sliceProductEdits;
