/* istanbul ignore file */

import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import {
    REACT_APP_AZURE_CLIENT_ID,
    REACT_APP_MIDDLEWARE_HOSTNAME,
    REACT_APP_AZURE_TENANT_LOGIN,
    REACT_APP_AZURE_SIGN_IN,
    REACT_APP_AZURE_FORGOT_PASSWORD,
    REACT_APP_AZURE_TENANT_DOMAIN,
    REACT_APP_AZURE_READ,
} from 'lily-env';

export const microsoftRedirectUri = window.location.origin.includes('app.local.lily.ai')
    ? `${window.location.origin}`
    : `${REACT_APP_MIDDLEWARE_HOSTNAME}/`;

export const b2cPolicies = {
    names: {
        signUpSignIn: REACT_APP_AZURE_SIGN_IN,
        forgotPassword: REACT_APP_AZURE_FORGOT_PASSWORD,
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${REACT_APP_AZURE_TENANT_LOGIN}/${REACT_APP_AZURE_TENANT_DOMAIN}/${REACT_APP_AZURE_SIGN_IN}`,
        },
        forgotPassword: {
            authority: `https://${REACT_APP_AZURE_TENANT_LOGIN}/${REACT_APP_AZURE_TENANT_DOMAIN}/${REACT_APP_AZURE_FORGOT_PASSWORD}`,
        },
    },
    authorityDomain: REACT_APP_AZURE_TENANT_LOGIN,
};

const msalB2CConfig = {
    auth: {
        clientId: REACT_APP_AZURE_CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: microsoftRedirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: `${microsoftRedirectUri}/login`, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                }
            },
        },
    },
};

export const b2cProtectedResources = {
    scopes: {
        read: [`https://${REACT_APP_AZURE_TENANT_DOMAIN}/${REACT_APP_AZURE_READ}`],
    },
};

export const b2cLoginRequest = {
    scopes: [...b2cProtectedResources.scopes.read],
    redirectUri: '/',
};

export const msalB2CInstance = new PublicClientApplication(msalB2CConfig);
