import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import { closeModal } from 'redux-state/modals/actions';
import React from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #E2DFDF',
    borderRadius: '4px',
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    padding: '20px 32px 10px 32px',
    outline: 'none',
};

const BaseModal = ({
    boxStyles,
    cancelLabel,
    confirmButtonStyles,
    confirmLabel,
    description,
    header,
    hideCancel,
    id,
    onCancel,
    onConfirm,
}: {
    boxStyles?: {
        [key: string]: any;
    };
    cancelLabel?: string;
    confirmButtonStyles?: {
        [key: string]: any;
    };
    confirmLabel?: string;
    description: string | React.ReactElement;
    header: string;
    hideCancel?: boolean;
    id: string;
    onCancel?: () => void;
    onConfirm?: () => void;
}) => {
    const dispatch = useDispatch();

    const handleCancel = () => {
        if (onCancel && typeof onCancel === 'function') {
            onCancel();
        }
        dispatch(closeModal(id));
    };

    const handleConfirm = () => {
        if (onConfirm && typeof onConfirm === 'function') {
            onConfirm();
        }
        dispatch(closeModal(id));
    };

    return (
        <Modal open={true}>
            <Box sx={{ ...style, ...boxStyles }}>
                <Typography component="h2" id="modal-modal-title" variant="h6">
                    {header}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {description}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button onClick={handleConfirm} sx={confirmButtonStyles || {}}>
                        {confirmLabel || 'Yes'}
                    </Button>
                    {!hideCancel && <Button onClick={handleCancel}>{cancelLabel || 'No'}</Button>}
                </Box>
            </Box>
        </Modal>
    );
};

export default BaseModal;
