import { Box, CircularProgress } from '@mui/material';
import { memo } from 'react';

const Loader = () => (
    <Box
        data-cy="loading"
        data-testid="loading"
        sx={{
            position: 'fixed',
            zIndex: 9999,
            left: '50vw',
            top: '50vh',
        }}
    >
        <CircularProgress size="4rem" style={{ color: '#ec3266' }} />
    </Box>
);

export default memo(Loader, () => true);
