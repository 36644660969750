import Cookies from 'js-cookie';
import { size } from 'lodash';

export enum JWT_TOKEN_STATUS {
    EXPIRED = 'EXPIRED',
    INVALID = 'INVALID',
    VALID = 'VALID',
}

export const validateToken = (): JWT_TOKEN_STATUS => {
    const token: string | undefined = Cookies.get('lilytoken');
    if (token === undefined) {
        return JWT_TOKEN_STATUS.INVALID;
    }

    let decodedToken = null;

    try {
        decodedToken = JSON.parse(atob(token.split('.')[1]));
        if (size(decodedToken) === 0) {
            return JWT_TOKEN_STATUS.INVALID;
        }
    } catch (e) {
        return JWT_TOKEN_STATUS.INVALID;
    }

    const expirationTime = decodedToken.exp * 1000;
    const currentTime = new Date().getTime();

    const isExpired = currentTime > expirationTime;

    if (isExpired) {
        return JWT_TOKEN_STATUS.EXPIRED;
    }

    return JWT_TOKEN_STATUS.VALID;
};

export const clearAnalyticsData = () => {
    localStorage.removeItem('__anon_id');
    localStorage.removeItem('__user_id');
    localStorage.removeItem('__user_traits');
    localStorage.removeItem('ajs_anonymous_id');
    localStorage.removeItem('ajs_group_id');
    localStorage.removeItem('ajs_user_id');
    localStorage.removeItem('ajs_user_traits');
};

export const clearAllDataOnLogout = () => {
    clearAnalyticsData();
    localStorage.removeItem('isAuth');
    localStorage.removeItem('isValid');
    Cookies.remove('lilytoken');
    localStorage.removeItem('index');
    localStorage.removeItem('type');
    localStorage.removeItem('signInType');
    sessionStorage.clear();
};

export const clearAllDataOnUserLogout = () => {
    clearAllDataOnLogout();
    localStorage.removeItem('brandsContext');
    localStorage.removeItem('activeUser');
};
