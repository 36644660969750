export interface ProxyProductRedux {
    allupdatedQueryProducts: AllupdatedQueryProducts;
    amountOfPages: number;
    batch: BatchName;
    batches: BatchName[];
    batchPaginated: Batch[];
    batchStatus: BatchStatus;
    currentPage: number;
    edited: string[];
    editingHero: Batch | null;
    editPercent: number;
    loading: boolean;
    perPage: number;
    updatedHero: Batch | null;
    viewed: string[];
    viewedPercent: number;
}

export class AllupdatedQueryProducts {
    batchID: string | null = null;
    batch: Batch[] = [];
}

export class BatchStatus {
    id: number = 0;
    batchName: string = '';
    addedON: string = '';
    brand: string = '';
    length: number = 0;
    status: string = '';
    totalQueryProductsCount: number = 0;
}

export class BatchName {
    batchName: string = '';
    batchID: string | null = null;
}

export interface Batch {
    proxyProducts: ProxyProduct[];
    queryProduct: string;
    signedURL: string;
}

export interface ProxyProduct {
    id: number | null;
    ranking: number;
    score: string;
    signedURL: string;
}

export interface FetchResultBatches {
    batches: BatchName[];
    date: Date;
    retailer: string;
}
