import BaseModal from 'wrappers/BaseModal';
import { Modal } from 'models/modals';
import { closeModal } from 'redux-state/modals/actions';
import { useDispatch } from 'redux-state/store';
import ProductDescriptionHeader from 'components/PAM/Views/ProductListDetailsComponents/ProductDescriptionHeader';
import { Stack } from '@mui/material';

const CONTENT_BOX_STYLES = {
    padding: '16.5px 14px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
};

const ProductChangedModal = ({ modal }: { modal: Modal }) => {
    const dispatch = useDispatch();

    const handleCancel = () => {
        modal?.props?.onReject?.();
        dispatch(closeModal(modal.id));
    };

    const handleConfirm = () => {
        modal?.props?.onSubmit?.();
    };

    const Description = () => (
        <Stack direction="column" sx={{ marginBottom: '10px' }}>
            Sorry, your changes were not saved because the product was updated. Please take a look at the recent update and decide if you want to
            overwrite it or discard your changes.
            <ProductDescriptionHeader>Recent title</ProductDescriptionHeader>
            <div dangerouslySetInnerHTML={{ __html: modal?.props?.productTitle }} style={CONTENT_BOX_STYLES} />
            <ProductDescriptionHeader>Recent description</ProductDescriptionHeader>
            <div dangerouslySetInnerHTML={{ __html: modal?.props?.productDescription }} style={CONTENT_BOX_STYLES} />
        </Stack>
    );

    return (
        <BaseModal
            boxStyles={{ width: 'minmax(60%, 800px)' }}
            cancelLabel="Discard my changes"
            confirmButtonStyles={{
                backgroundColor: 'transparent',
                color: '#3c4043',
                opacity: '.9',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            }}
            confirmLabel="Overwrite recent change"
            description={<Description />}
            header="Your changes were not saved!"
            id={modal.id}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
        />
    );
};

export default ProductChangedModal;
