import BaseModal from 'wrappers/BaseModal';
import { Modal } from 'models/modals';
import analytics from 'helpers/analytics';
import { lilyJwt } from 'helpers/ParseJWT';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

const LoginFailureModal = ({ modal }: { modal: Modal }) => {
    const LOGIN_FAILED: string = 'Login Failed';
    //send analytics to Segment
    const JWT = lilyJwt();
    const email: string = JWT?.sub;
    const { userName } = JWT || {};
    const navigate = useNavigate();

    if (JWT) {
        analytics.track(
            LOGIN_FAILED,
            {
                userName,
                userEmail: email,
                isCustomer: !(email || '').toLowerCase().includes('@lily.ai'),
            },
            { timestamp: new Date().toISOString() }
        );
        analytics.reset();
    }

    const handleConfirm = useCallback(() => {
        localStorage.removeItem('activeUser');
        modal?.props?.onSubmit?.();
        navigate('/login');
    }, []);

    return (
        <BaseModal
            confirmLabel="Ok"
            description={
                modal.props?.description || 'Please reach out to your Customer Success representative for help getting access to the Lily App'
            }
            header={LOGIN_FAILED}
            hideCancel
            id={modal.id}
            onConfirm={handleConfirm}
        />
    );
};

export default LoginFailureModal;
