import React, { useEffect, useMemo } from 'react';
import analytics from 'helpers/analytics';
import useBrand from 'helpers/useBrand';
import { isCustomer } from 'helpers/viewerHelpers';

interface SegmentWrapper {
    children: React.ReactNode;
    name: string;
}

const SegmentWrapper = ({ children, name }: SegmentWrapper) => {
    const { brandsContext } = useBrand();
    const { currentRetailer } = brandsContext;
    const subsidiaryAccount = useMemo(() => currentRetailer?.name, [currentRetailer]);

    useEffect(() => {
        analytics.page({ name, subsidiaryAccount, isCustomer: isCustomer() }, { timestamp: new Date().toISOString() });
    }, [name, subsidiaryAccount]);

    return <>{children}</>;
};

export default SegmentWrapper;
