import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, isEqual } from 'lodash';

import { AppThunk } from 'redux-state/store';
import { FiltersRedux } from 'models/PAM/Filters';
import { Tag } from 'models/PAM/PAM';

const initialState: FiltersRedux[] = [];

const sliceProductCopyFilter = createSlice({
    name: 'filterProductCopyTags',
    initialState,
    reducers: {
        updateProductCopyFilterTags(state: FiltersRedux[], action: PayloadAction<FiltersRedux>) {
            const key: string = get(action, 'key', '');
            const { category, label, tag }: Tag = get(action, 'label', new Tag());
            const duplicate = state.some((x) => isEqual(x[key], { category, label, tag }));

            if (duplicate) {
                return state.filter((item) => JSON.stringify(item[key]) !== JSON.stringify({ category, label, tag }));
            }

            const newTag: FiltersRedux = { [key]: { category, label, tag } };
            return state.concat(newTag);
        },
        clearProductCopyFilterTags(_state) {
            return initialState;
        },
    },
});

export const doClearProductFilterTags =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(sliceProductCopyFilter.actions.clearProductCopyFilterTags());
    };

export const { reducer } = sliceProductCopyFilter;
