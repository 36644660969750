import { AppThunk } from 'redux-state/store';
import modalSlice from './reducers';
import { ModalProps } from 'models/modals';

export const openModal =
    (id: string, props?: ModalProps): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(modalSlice.actions.openModal({ id, props }));
    };

export const closeModal =
    (id: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(modalSlice.actions.closeModal(id));
    };
