import { find, orderBy } from 'lodash';
import { JWT_TOKEN_STATUS, validateToken, clearAllDataOnLogout } from 'helpers/loginHelpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'redux-state/store';

import { Retailer } from 'models/brands/brands';
import { TrackUserData } from 'models/analytics/TrackUser';
import useBrand from 'helpers/useBrand';
import { useLocation, useNavigate } from 'react-router-dom';
import { isStageOnLocal } from 'helpers/env';
import { lilyJwt } from 'helpers/ParseJWT';
import { sendUserAnalytics } from 'helpers/analytics';
import { MODAL_TYPES } from 'constants/modalTypes';
import { openModal } from 'redux-state/modals/actions';
import { resetLoginState } from 'redux-state/oauth';
import NotPermitted from './401NoPermission';
import { isCustomer } from 'helpers/viewerHelpers';
import { EvaluationContext, OpenFeature } from '@openfeature/web-sdk';

interface TAuthWrapper {
    children: React.ReactChild | React.ReactChild[];
}

const AuthWrapper = ({ children }: TAuthWrapper) => {
    const dispatch = useDispatch();
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isUserDetailsRequested, setIsUserDetailsRequested] = useState(false);
    const [isAnalyticsReady, setAnalyticsReady] = useState(false);
    const { isLoggingIn, isLoginFailure, isLogInSuccess } = useSelector((state) => state.oauth);
    const location = useLocation();
    const currentLocation = location.pathname;
    const isLoginPage = currentLocation === '/login';
    const jwtStatus = validateToken();
    const { brandsContext, saveBrand } = useBrand();
    const { currentRetailer } = brandsContext;
    //only offical TS work around involves using Next.js in place of react-router
    const navigate = useNavigate();

    const getBrands = useCallback(async () => {
        //get and set brand <> user info
        const JWT = lilyJwt();
        const email: string = JWT?.sub;
        const userID: number = JWT?.id;
        const { firstName, lastName, role, userName } = JWT || {};
        const sortedList = orderBy(JWT?.brands, 'name');
        const currentRetailerCode = isStageOnLocal() ? 'terra' : currentRetailer?.code;
        const retailer: Retailer = find(sortedList, (brand: Retailer) => brand.code === currentRetailerCode) || sortedList[0];

        //send analytics to Segment
        const trackData: TrackUserData = {
            email,
            firstName,
            isCustomer: isCustomer(email),
            lastName,
            userName,
            subsidiaryAccount: retailer.name,
        };

        sendUserAnalytics(trackData, userID).then(() => setAnalyticsReady(true));

        const newContext: EvaluationContext = {
            targetingKey: `${userID}`,
            retailer: retailer.code,
            email,
            userName,
            userID: `${userID}`,
            role,
            lastName,
            firstName,
        };
        //set user in darkly
        await OpenFeature.setContext(newContext);

        saveBrand({
            currentRetailer: retailer,
        });

        //set boolean to mark info as recieved
        setIsUserDetailsRequested(true);
    }, [isTokenValid]);

    useEffect(() => {
        //action taken based on JWT status
        switch (jwtStatus) {
            case JWT_TOKEN_STATUS.VALID: {
                setIsTokenValid(true);
                break;
            }
            case JWT_TOKEN_STATUS.EXPIRED:
            case JWT_TOKEN_STATUS.INVALID:
            default: {
                dispatch(resetLoginState());
                setIsTokenValid(false);
                if (!isLoginPage) navigate('/login', { replace: true });
                break;
            }
        }
    }, [isLoginPage, jwtStatus]);

    useEffect(() => {
        if (isTokenValid && !isUserDetailsRequested) {
            getBrands();
        }
    }, [isTokenValid, isUserDetailsRequested]);

    useEffect(() => {
        if (isLoginFailure) {
            dispatch(
                openModal(MODAL_TYPES.LOGIN_FAILURE, {
                    onSubmit: () => {
                        clearAllDataOnLogout();
                        navigate('/login', { replace: true });
                    },
                })
            );
        }
    }, [isLoginFailure]);

    switch (true) {
        case isLoginFailure:
            //use should be returned to login screen during this step
            //Modal is launched
            return <NotPermitted />;
        case isLoginPage && !isLogInSuccess && !isLoggingIn: //user can see login screen
        case isTokenValid && isAnalyticsReady: //user can view other roots if their JWT is valid
            return <>{children}</>;
        case isLoggingIn:
        case isLogInSuccess && isLoginPage:
        default:
            //edge cases
            return null;
    }
};

export default AuthWrapper;
