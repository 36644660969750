import { Tag } from './PAM';

export class Filters {
    subsidiary: Tag[] = [];
    product: Tag[] = [];
    person: Tag[] = [];
}

export interface FiltersRedux {
    [key: string]: Tag;
}

export enum FilterTypes {
    SUBSIDIARYTAGS = 'SUBSIDIARY_TAG',
    CATEGORY = 'CATEGORY',
}

export class FiltersResponse {
    piTitle: string = '';
    filters: FiltersList[] = [];
    piSubsidiaryCode: string = '';
    piType: string = '';
    pamDiscriminator: FilterTypes = FilterTypes.SUBSIDIARYTAGS;
}

export class FiltersList {
    piTag: string = '';
    pamLabel: string = '';
    id: number = 0;
}
