import './Styles/NavButton.scss';

import { Box, Button } from '@mui/material';
import { NavBarButtonStatus, TNavButton } from 'models/navigations/Sidebar';

import { NavLink } from 'react-router-dom';
import { SetStateAction, memo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { size } from 'lodash';

const StyledNavLink = styled(NavLink)(() => ({
    '&.Mui-selected': {
        backgroundColor: 'var(--selected-color) !important',
    },
    fontSize: '16px !important',
}));

interface INavButton {
    handleListItemClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: SetStateAction<number>) => void;
    index: number;
    item: TNavButton;
    selectedIndex: number;
}

const NavButton = memo(({ handleListItemClick, index, item, selectedIndex }: INavButton) => {
    const theme = useTheme();
    return (
        <>
            {item.status === NavBarButtonStatus.INVISIBLE ? (
                <></>
            ) : (
                <Box
                    key={index}
                    sx={{
                        display: 'block',
                        mt: '15px',
                        mb: '15px',
                        borderRadius: '8px',
                        '&:hover': { backgroundColor: 'rgba(242, 249, 251, 0.85)' },
                    }}
                >
                    <Button
                        component={StyledNavLink}
                        data-cy={item.redirectTo}
                        data-testid={`nav-button-${item.label.replace(' ', '-')}`}
                        disabled={item.status === NavBarButtonStatus.GREYED}
                        onClick={(e) => handleListItemClick(e, index)}
                        size="small"
                        startIcon={
                            <item.icon
                                sx={{
                                    minWidth: 0,
                                    justifyContent: 'center',
                                    backgroundColor: 'rgba(242, 249, 251, 0.75)',
                                    color: size(item.color) ? item.color : theme.palette.primary.main,
                                    padding: '1px',
                                }}
                            ></item.icon>
                        }
                        sx={{
                            padding: '8px',
                            minHeight: 48,
                            borderRadius: '8px',
                            color: '#3c4043',
                            backgroundColor: selectedIndex === index ? 'rgba(242, 249, 251, 0.75)' : '',
                        }}
                        to={item.redirectTo}
                    >
                        {item.label}
                    </Button>
                </Box>
            )}
        </>
    );
});

export default NavButton;
