import { Slide, Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';
import { closeDebugSnackbar } from 'redux-state/debugSnackbar/actions';
import { useSelector, useDispatch } from 'redux-state/store';
import './Syles/DebugSnackbar.scss';
import { REACT_APP_ENV_NAME } from 'lily-env';
import { capitalize, size } from 'lodash';
import { piRequest } from 'models/debugSnackbar/debugSnackbar';
import { JWT_TOKEN_STATUS, validateToken } from 'helpers/loginHelpers';
import { lilyJwt } from 'helpers/ParseJWT';

const DebugSnackbar = () => {
    const jwtStatus = validateToken();
    const dispatch = useDispatch();
    const { message, open } = useSelector((state) => state.DebugSnackbar);

    let isUserAdmin = false;
    if (jwtStatus === JWT_TOKEN_STATUS.VALID) {
        const JWT = lilyJwt();
        isUserAdmin = JWT?.role === 'LILY ADMIN';
    }

    const debugMode = localStorage.getItem('debugMode');
    const debugModeEnabled = debugMode === 'true';

    const handleClose = () => {
        dispatch(closeDebugSnackbar());
    };
    const Transition = forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>
    ) {
        return <Slide direction="right" ref={ref} {...props} />;
    });
    const DIALOG_PAPER_PROPS = { className: 'drawerContainer' };

    if (size(message) > 0) {
        console.error('API ERROR: ', message);
    }

    let piError: piRequest = {};
    if (message && message.data.error) {
        piError = message.data.error;
    }

    return (REACT_APP_ENV_NAME !== 'production' || isUserAdmin) && debugModeEnabled ? (
        <>
            {open && (
                <Dialog
                    aria-describedby="alert-debug"
                    hideBackdrop
                    keepMounted
                    onClose={handleClose}
                    open={open}
                    PaperProps={DIALOG_PAPER_PROPS}
                    scroll="body"
                    TransitionComponent={Transition}
                >
                    <DialogTitle>{`${capitalize(REACT_APP_ENV_NAME)} Fetch Debugger`}</DialogTitle>
                    <DialogContent>
                        <>
                            {message ? (
                                <>
                                    <Typography>{`Status: ${message.status}`}</Typography>
                                    <Typography>{`URL: ${message.config.url}`}</Typography>
                                    {size(piError) > 0 && (
                                        <>
                                            <Typography>{`Location: ${piError.errorLocation}`}</Typography>
                                            <Typography>{`PI Message: ${piError.errorMessage}`}</Typography>
                                            <Typography>{`Check 1: ${piError.check1}`}</Typography>
                                            <Typography>{`Check 2: ${piError.check2}`}</Typography>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Typography>Unidentifiable error</Typography>
                            )}
                            <Typography>More info in console</Typography>
                        </>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    ) : null;
};

export default DebugSnackbar;
