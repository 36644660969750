import { ReactChild, useCallback, useEffect, useMemo } from 'react';

import { LoginModel } from 'models/auth/login';
import { azureLoginRequest } from 'helpers/azureLogin';
import { b2cLoginRequest } from 'helpers/b2cLogin';
import { clearAllDataOnUserLogout } from 'helpers/loginHelpers';
import { MsalAuthenticationTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'redux-state/store';
import { login } from 'redux-state/oauth';

import Loader from 'components/common/Loader';
import { getIDToken } from '@actions/core';

const UnauthenticatedUser = ({ handleError }: { handleError: () => void }) => {
    const { isLogoutInProgress } = useSelector((state) => state.oauth);
    useEffect(() => {
        if (!isLogoutInProgress) {
            console.info(`[LilyTokenFetcher UnauthenticatedUser]: going to clearAllDataOnUserLogout due to !isLogoutInProgress`);
            handleError();
        }
    }, []);

    return null;
};

const AuthenticatedUser = ({ children, handleError }: { children: ReactChild; handleError: () => void }) => {
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const activeAccount = useMemo(() => instance.getActiveAccount() || undefined, [instance]);
    const username = useMemo(() => activeAccount?.username, [activeAccount]);
    const isInternal = useMemo(() => username?.includes('@lily.ai'), [username]);
    const { isLoggingIn, isLoginFailure, isLogInSuccess, isLogoutInProgress } = useSelector((state) => state.oauth);

    useEffect(() => {
        instance
            .acquireTokenSilent({ ...(isInternal ? azureLoginRequest : b2cLoginRequest), account: activeAccount })
            .then((resp) => {
                const payload: LoginModel = {
                    type: 'azure',
                };
                dispatch(login(payload, resp.idToken));
            })
            .catch((err) => {
                console.warn('Error while performing acquireTokenSilent:', err);
                handleError();
            });
    }, []);

    useEffect(() => {
        if (isLoginFailure && !isLogoutInProgress) {
            localStorage.removeItem('activeUser');
            sessionStorage.clear();
            console.info(
                `[LilyTokenFetcher]: going to clearAllDataOnUserLogout due to isLoginFailure && !isLogoutInProgress: ${
                    isLoginFailure && !isLogoutInProgress
                }`
            );
            clearAllDataOnUserLogout();
            instance.logoutRedirect({ postLogoutRedirectUri: '/login' });
        }
    }, [isLoginFailure, isLogoutInProgress]);

    useEffect(() => {
        if (isLogoutInProgress) {
            localStorage.removeItem('activeUser');
            sessionStorage.clear();
            console.info(`[LilyTokenFetcher]: going to clearAllDataOnUserLogout due to isLogoutInProgress: ${isLogoutInProgress}`);
            clearAllDataOnUserLogout();
            instance.logoutRedirect({ postLogoutRedirectUri: '/login' });
        }
    }, [isLogoutInProgress]);

    return isLogInSuccess && !isLogoutInProgress ? <>{children}</> : <Loader />;
};

const LilyTokenFetcher = ({ children }: any) => {
    const navigate = useNavigate();
    const handleError = useCallback(() => {
        localStorage.removeItem('activeUser');
        sessionStorage.clear();
        clearAllDataOnUserLogout();

        navigate('/login');
    }, []);

    return (
        <>
            <MsalAuthenticationTemplate
                errorComponent={(err) => {
                    err.login();
                    return <></>;
                }}
                interactionType={InteractionType.Redirect}
                loadingComponent={Loader}
            >
                <AuthenticatedUser handleError={handleError}>{children}</AuthenticatedUser>
            </MsalAuthenticationTemplate>
            <UnauthenticatedTemplate>
                <UnauthenticatedUser handleError={handleError} />
            </UnauthenticatedTemplate>
        </>
    );
};

export default LilyTokenFetcher;
