import Cookies from 'js-cookie';
import { decodeToken } from 'react-jwt';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OAuth } from 'models/oauth';

const initialState: OAuth = {
    isLoggingIn: false,
    isLogInSuccess: false,
    isLoginFailure: false,
    isLogoutInProgress: false,
};

const oauthSlice = createSlice({
    name: 'oauth',
    initialState,
    reducers: {
        loginSuccess(state: OAuth, action: PayloadAction<string>): void {
            const token = action.payload;
            const expires = new Date(((<{ exp: number }>decodeToken(token) || { exp: 0 }).exp || 0) * 1000);
            console.info(
                `[loginSuccess]: ${(<{ exp: number }>decodeToken(token) || { exp: 0 }).exp ? 'token was decoded' : 'token was not decoded'}`
            );
            console.info(`[loginSuccess]: expires: ${expires}`);
            Cookies.set('lilytoken', token, { expires });
            state.isLoggingIn = false;
            state.isLoginFailure = false;
            state.isLogInSuccess = true;
        },
        loginRequested(state: OAuth): void {
            state.isLogInSuccess = false;
            state.isLoginFailure = false;
            state.isLoggingIn = true;
        },
        loginFailed(state: OAuth): void {
            state.isLoggingIn = false;
            state.isLogInSuccess = false;
            state.isLoginFailure = true;
        },
        resetLogin(state: OAuth): void {
            state.isLogInSuccess = false;
            state.isLoginFailure = false;
            state.isLoggingIn = false;
        },
        logout(state: OAuth): void {
            state.isLogoutInProgress = true;
        },
    },
});

export const { reducer } = oauthSlice;

export default oauthSlice;
