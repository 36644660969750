import { lilyJwt } from './ParseJWT';

export const isCustomer = (email?: string) => {
    let userEmail = email;
    if (!userEmail) {
        const JWT = lilyJwt();
        userEmail = JWT?.sub;
    }

    return !(userEmail || '').toLowerCase().includes('@lily.ai');
};

export const getUserID = () => {
    const JWT = lilyJwt();
    return JWT?.id;
};
