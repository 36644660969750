import BaseModal from 'wrappers/BaseModal';
import { Modal } from 'models/modals';
import { closeModal } from 'redux-state/modals/actions';
import { useDispatch } from 'redux-state/store';
import { setUnsavedProductChanges } from 'redux-state/productCopy/productUpdates/actions';

const UnsavedChangesModal = ({ modal }: { modal: Modal }) => {
    const dispatch = useDispatch();

    const handleCancel = () => {
        modal?.props?.onReject?.();
        dispatch(closeModal(modal.id));
    };

    const handleConfirm = () => {
        dispatch(setUnsavedProductChanges(false));
        modal?.props?.onSubmit?.();
    };

    return (
        <BaseModal
            cancelLabel="Keep me here"
            confirmButtonStyles={{
                backgroundColor: 'transparent',
                color: '#3c4043',
                opacity: '.9',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            }}
            confirmLabel="Let me continue"
            description="You have unsaved changes! If you continue you will lose the changes."
            header="Unsaved content warning"
            id={modal.id}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
        />
    );
};

export default UnsavedChangesModal;
