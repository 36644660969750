import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';

import { isStageOnLocal } from './helpers/env';

const RunningOnStageWarning = () => {
    const [show, setShow] = useState(isStageOnLocal());

    const hideWarning = () => setShow(false);

    return !show ? null : (
        <Box
            sx={{
                position: 'fixed',
                zIndex: 2000,
                width: '100%',
                background: '#ffb74d',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Typography sx={{ color: '#424242', textAlign: 'center', width: '100%', padding: '5px 0' }}>
                You are running your local app on staging environment. Be careful! Production PI APIs are used! To avoid unwanted effects, your
                retailer was switched to Terra
            </Typography>
            <Button
                onClick={hideWarning}
                sx={{
                    border: 'none',
                    background: 'transparent',
                    color: '#424242',
                    outline: 'none',
                    '&:hover': { border: 'none', background: 'transparent' },
                }}
                variant="outlined"
            >
                <CloseIcon sx={{ outline: 'none' }} />
            </Button>
        </Box>
    );
};

export default RunningOnStageWarning;
