import { AxiosError } from 'axios';
import { REACT_APP_MIDDLEWARE_API } from 'lily-env';
import { AppThunk } from 'redux-state/store';
import sliceProductCopy from './reducers';
import { openErrorGlobalSnackbar, openSuccessGlobalSnackbar } from 'redux-state/globalSnackbar/actions';
import axiosInstance from 'helpers/AxiosInstance';
import { ProductCopyDetailsUpdate } from 'models/ProductCopy/ProductCopy';
// Function for making post request to /auth/user/login

export const updateProductDescriptionAndTitle =
    (data: ProductCopyDetailsUpdate): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(sliceProductCopy.actions.updateTitleAndDescription({ product_id: data.product_id }));
        axiosInstance
            .put(`${REACT_APP_MIDDLEWARE_API}/productcopy/products/details/${data.product_id}`, data)
            .then((response) => {
                dispatch(sliceProductCopy.actions.updateTitleAndDescriptionSuccess(data));
                dispatch(
                    openSuccessGlobalSnackbar({
                        title: 'Congratulations!',
                        message: `Product title and description were successfully updated`,
                    })
                );
            })
            .catch((error: AxiosError) => {
                dispatch(sliceProductCopy.actions.updateTitleAndDescriptionFailure({ product_id: data.product_id }));
                //display error message
                dispatch(
                    openErrorGlobalSnackbar({
                        title: 'Product title and description update has failed',
                        message: `${JSON.stringify(error.response?.data)}`,
                    })
                );
            });
    };

export const setUnsavedProductChanges =
    (hasUnsaved: boolean): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(sliceProductCopy.actions.setUnsavedProductChanges({ hasUnsaved }));
    };
