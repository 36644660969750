import { Stack } from '@mui/material';
import { encryptLocalData, getEncyptedLocalData } from 'helpers/EncryptLocalStorage';
import { NavBarButtonStatus, TNavButton } from 'models/navigations/Sidebar';
import { SetStateAction, useEffect, useState } from 'react';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import Leaderboard from '@mui/icons-material/Leaderboard';
import Loyalty from '@mui/icons-material/Loyalty';
import NavButton from './NavButton';
import ScatterPlot from '@mui/icons-material/ScatterPlot';
import BoltIcon from '@mui/icons-material/Bolt';
import { OpenFeature } from '@openfeature/web-sdk';
import PowerIcon from '@mui/icons-material/Power';
import ProductCopyIcon from 'assets/icons/ProductCopyIcon';

const NavButtonList = () => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const flagStatus = (permissionNeeded: string) => {
        const client = OpenFeature.getClient();
        return client.getStringValue<'Invisible' | 'Greyed' | 'Clickable'>(permissionNeeded, 'Invisible');
    };

    const sidebarMenu: TNavButton[] = [
        {
            label: 'Catalog Manager',
            icon: Loyalty,
            redirectTo: `/catalogmanager`,
            status: NavBarButtonStatus.CLICKABLE,
        },
        {
            label: 'Integrations',
            icon: PowerIcon,
            redirectTo: `/integrations`,
            status: flagStatus('Integrations20240529'),
            color: '#8bbe9c',
        },
        {
            label: 'Product Copy',
            icon: ProductCopyIcon,
            redirectTo: `/productcopy`,
            status: flagStatus('CopyProductPerm20231208'),
        },
        {
            label: 'Analytics',
            icon: Leaderboard,
            redirectTo: '/analytics',
            status: flagStatus('isAnalytics21EnabledPermAnalytics20231205'),
            color: '#71acbb',
        },
        {
            label: 'Core Trends',
            icon: BoltIcon,
            redirectTo: '/trends',
            status: flagStatus('isConversionsAndTrendsAccessible20231206'),
            color: '#8bbe9c',
        },

        {
            label: 'Conversions',
            icon: CallMissedOutgoingIcon,
            redirectTo: '/conversions',
            status: flagStatus('isConversionsAndTrendsAccessible20231206'),
        },
        {
            label: 'Proxy Products',
            icon: ScatterPlot,
            redirectTo: '/proxyproduct',
            status: flagStatus('proxyProductStatusPermProxyProduct20221118'),
            color: '#71acbb',
        },
    ];

    const handleListItemClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: SetStateAction<number>) => {
        setSelectedIndex(index);
        encryptLocalData('index', index);
    };

    useEffect(() => {
        setSelectedIndex(getEncyptedLocalData('index') || 0);
    }, [selectedIndex]);

    return (
        <Stack alignItems="center" direction="row" justifyContent="flex-end" spacing={2} sx={{ py: '8px' }}>
            {sidebarMenu.map((item: TNavButton, index: number) => (
                <NavButton handleListItemClick={handleListItemClick} index={index} item={item} key={item.label} selectedIndex={selectedIndex} />
            ))}
        </Stack>
    );
};

export default NavButtonList;
