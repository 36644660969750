import { createSlice } from '@reduxjs/toolkit';

import { ICatelogManager } from 'v2/models/redux/catalogManager';

const initialState: ICatelogManager = {
    batch: undefined,
    product: undefined,
    viewType: 'batch',
    productSearchFilter: undefined,
    ingestionsIdsList: [],
    dateRange: {
        startDate: null,
        endDate: null,
    },
};

/**
 * @description Catalog Manager Slice & reducer
 */
const catalogManagerSlice = createSlice({
    name: 'catalogManager',
    initialState,
    reducers: {
        setBatch: (state: ICatelogManager, action): void => {
            state.batch = action.payload;
        },
        setBatchProduct: (state: ICatelogManager, action): void => {
            state.product = action.payload;
        },
        switchCatalogViewType: (state: ICatelogManager, action): void => {
            state.viewType = action.payload;
            state.dateRange = {
                startDate: null,
                endDate: null,
            };
        },
        setProductFilters: (state: ICatelogManager, action): void => {
            state.productSearchFilter = action.payload;
        },
        setIngestionIds: (state: ICatelogManager, action): void => {
            state.ingestionsIdsList = action.payload;
        },
        setDateRangeStart: (state: ICatelogManager, action): void => {
            state.dateRange.startDate = action.payload?.startDate;
        },
        setDateRangeEnd: (state: ICatelogManager, action): void => {
            state.dateRange.endDate = action.payload?.endDate;
        },
        resetCatalogmanagerState: (state: ICatelogManager): ICatelogManager => initialState,
    },
});

export default catalogManagerSlice;
