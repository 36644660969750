import { Switch, Tooltip } from '@mui/material';
import { useState } from 'react';

export const DebugSwitch = () => {
    const debugMode = localStorage.getItem('debugMode');
    const [enabled, setEnabled] = useState(debugMode === 'true');
    const handleClick = () => {
        //cannot to toString with bash
        const newBoolean = !enabled;
        localStorage.setItem('debugMode', newBoolean.toString());
        setEnabled(newBoolean);
    };

    return (
        <Tooltip title="Toogle Debug Mode">
            <Switch checked={enabled} onClick={handleClick} />
        </Tooltip>
    );
};
