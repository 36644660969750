import { Box, useTheme } from '@mui/material';
import NavBar from 'containers/Navbar/Nav';
import { Outlet, useLocation } from 'react-router-dom';

const OUTLET_CONTAINER_STYLES = { display: 'flex', height: '100%' };
const NAV_STYLES = { zIndex: 1300, marginBottom: '50px' };
const MAIN_STYLES = { flexGrow: 1, py: 3, px: 2, m: 3, overflow: 'hidden', paddingBottom: 0 };

const OutletContainer = () => {
    const location = useLocation();
    const { breakpoints } = useTheme();

    return (
        <Box sx={OUTLET_CONTAINER_STYLES}>
            <Box sx={NAV_STYLES}>
                <NavBar />
            </Box>
            <Box
                component="main"
                sx={[
                    MAIN_STYLES,
                    location.pathname === '/integrations' && {
                        [breakpoints.up('md')]: {
                            mr: 0,
                            pr: 0,
                            pb: 0,
                            mb: 0,
                            pl: 5,
                        },
                    },
                    location.pathname.includes('/integrations/connect/') && {
                        [breakpoints.up('md')]: {
                            mr: 0,
                            pr: 0,
                            pb: 0,
                            mb: 0,
                            pl: 0,
                            ml: 0,
                        },
                    },
                ]}
            >
                <Outlet />
            </Box>
        </Box>
    );
};

export default OutletContainer;
