import { IProductWithSynonym } from 'v2/models/IProductSearchResponse';
import store from '../store';
import catalogManagerSlice from './slice';
import { IProductFilters, ListViewTypes } from 'v2/models/redux/catalogManager';
import { IBatchList } from 'v2/models/IBatchList';

/**
 * @description Catalog Manager State
 * @description define all the action inside catalogManagerState
 */
export const catalogManagerState = {
    /**
     * @description Sets batch id of for the selected Batch
     * @param {string | undefined} batch
     * ```
     * catalogManagerState.setSelectedBatchId("batch_id")
     * ```
     */
    setSelectedBatch: (batch: IBatchList | undefined): void => {
        store.dispatch(catalogManagerSlice.actions.setBatch(batch));
    },

    /**
     * @description sets product id for the selected product of a batch
     * @param {number}id
     * ```
     * catalogManagerState.setBatchProductId("pid")
     * ```
     */
    setBatchProduct: (product: IProductWithSynonym | undefined): void => {
        store.dispatch(catalogManagerSlice.actions.setBatchProduct(product));
    },
    /**
     * @description sets batch/product list type view
     * @param {string} viewType
     * ```
     * catalogManagerState.toggleListLayout("batch")
     * ```
     */
    toggleListLayout: (viewType: ListViewTypes): void => {
        store.dispatch(catalogManagerSlice.actions.switchCatalogViewType(viewType));
    },
    /**
     * @description sets product within batch filters
     * @param {IProductFilters | undefined} view
     * ```
     * catalogManagerState.setProductFilterInBatch(values)
     * ```
     */
    setProductFilterInBatch: (value: IProductFilters | undefined): void => {
        store.dispatch(catalogManagerSlice.actions.setProductFilters(value));
    },
    /**
     * @description sets ids of fetched ingestions
     * @param {Array<number>} value
     * ```
     * catalogManagerState.setFetchedIngestionIds(values)
     * ```
     */
    setFetchedIngestionIds: (value: Array<number>): void => {
        store.dispatch(catalogManagerSlice.actions.setIngestionIds(value));
    },
    /**
     * @description sets start date of date range
     * @param {value: string | null} value
     * ```
     * catalogManagerState.setStartDateRange(values)
     * ```
     */
    setStartDateRange: (value: string | null): void => {
        store.dispatch(catalogManagerSlice.actions.setDateRangeStart({ startDate: value }));
    },
    /**
     * @description sets end date of date range
     * @param {value: string | null} value
     * ```
     * catalogManagerState.setEndDateRange(values)
     * ```
     */
    setEndDateRange: (value: string | null): void => {
        store.dispatch(catalogManagerSlice.actions.setDateRangeEnd({ endDate: value }));
    },
    /**
     * @description Reset all redux state of catalog mangager
     * ```
     * catalogManagerState.resetCatalogmanagerStates()
     * ```
     */
    resetCatalogmanagerStates: (): void => {
        store.dispatch(catalogManagerSlice.actions.resetCatalogmanagerState());
    },
};
