import { createSlice } from '@reduxjs/toolkit';
import { decodeBase64ToString } from 'helpers/encodingHelpers';

const initialState = {
    hasUnsavedChanges: false,
};

const sliceProductCopy = createSlice({
    name: 'productCopyUpdates',
    initialState,
    reducers: {
        updateTitleAndDescription(state, action) {
            state[action?.payload?.product_id] = {
                updateInProgress: true,
                updateSucceeded: false,
                updateFailed: false,
            };
        },
        updateTitleAndDescriptionSuccess(state, action) {
            state[action?.payload?.product_id] = {
                updateInProgress: false,
                updateSucceeded: true,
                updateFailed: false,
                updatedTitle: action?.payload?.title,
                updatedDescription: decodeBase64ToString(action?.payload?.description),
            };
            console.warn(state[action?.payload?.product_id]);
        },
        updateTitleAndDescriptionFailure(state, action) {
            state[action?.payload?.product_id] = {
                updateInProgress: false,
                updateSucceeded: false,
                updateFailed: true,
            };
        },
        setUnsavedProductChanges(state, action) {
            state.hasUnsavedChanges = action.payload.hasUnsaved;
        },
    },
});

export const { reducer } = sliceProductCopy;

export default sliceProductCopy;
