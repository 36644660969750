export interface BrandsContext {
    currentRetailer: Retailer;
}

export class Retailer {
    id: number = 0;
    name: string = '';
    code: string = '';
}

export class SubsidiaryStatus {
    hasSubidiaries: boolean = false;
    isSubsidiary: boolean = false;
    hasOrIsSubsidiary: boolean = false;
}
