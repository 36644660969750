import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const start = dayjs().subtract(7, 'd').format('YYYY-MM-DD');

const end = dayjs().subtract(1, 'days').format('YYYY-MM-DD');

const diffDates = dayjs(end).diff(dayjs(start), 'days');

const compareSdate = dayjs(start)
    .subtract(diffDates + 1, 'd')
    .format('YYYY-MM-DD');

const compareEnDate = dayjs(end)
    .subtract(diffDates + 1, 'days')
    .format('YYYY-MM-DD');

const initialState = {
    startDate: start,
    endDate: end,
    compareStartDate: compareSdate,
    compareEndDate: compareEnDate,
};

const sliceAnalyticsDashboard = createSlice({
    name: 'AnalyticsDashboard',
    initialState,
    reducers: {
        setStartDate(state, action) {
            state.startDate = action.payload;
        },
        setEndDate(state, action) {
            state.endDate = action.payload;
        },
        setCompareStartDate(state, action) {
            state.compareStartDate = action.payload;
        },
        setCompareEndDate(state, action) {
            state.compareEndDate = action.payload;
        },
    },
});

export const { reducer } = sliceAnalyticsDashboard;

export default sliceAnalyticsDashboard;
