import { Retailer } from 'models/brands/brands';

export interface LoginModel {
    type: string;
}

export class LilyJWT {
    allowedServices: string[] = [];
    aud: string = '';
    brands: Retailer[] = [];
    exp: number = 0;
    firstName: string = '';
    iat: number = 0;
    id: number = 0;
    iss: string = '';
    lastName: string = '';
    role: string = '';
    sub: string = '';
    userName: string = '';
    userSurrogateID: string = '';
}
