import { Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

export const ProductDescriptionHeaderPlaceholder = () => (
    <Skeleton sx={{ bgcolor: '#f5f5f5', fontSize: '14px', lineHeight: '18px', margin: '16px 0', width: '10%' }} variant="text" />
);

const ProductDescriptionHeader = ({ children }: { children?: ReactNode }) => (
    <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '14px', lineHeight: '18px', color: '#3c4043', margin: '16px 0' }}>
        {children}
    </Typography>
);

export default ProductDescriptionHeader;
