import { Stack, Typography, Button } from '@mui/material';
import { msalInstance } from 'helpers/azureLogin';
import { msalB2CInstance } from 'helpers/b2cLogin';
import LoginBorder from 'components/common/LoginBorder';

const NotPermitted = () => {
    const backToHome = () => {
        const type = localStorage.getItem('type');
        if (type === 'b2c') {
            msalB2CInstance.logoutRedirect({ postLogoutRedirectUri: '/login' });
        } else if (type === 'azure') {
            msalInstance.logoutRedirect({ postLogoutRedirectUri: '/login' });
        }
    };

    return (
        <LoginBorder>
            <Stack alignItems="center" direction="column" justifyContent="center" spacing={2}>
                <Typography
                    style={{
                        top: '100px',
                        textAlign: 'center',
                        fontSize: '250px',
                        textSizeAdjust: '100%',
                    }}
                    variant="h1"
                >
                    401
                </Typography>
                <Typography
                    style={{
                        textAlign: 'center',
                        fontSize: '65px',
                        textSizeAdjust: '100%',
                        color: '#1D1D1F',
                    }}
                >
                    Not Permitted
                </Typography>
                <Button onClick={backToHome} style={{ width: '10rem' }}>
                    Return to Login
                </Button>
            </Stack>
        </LoginBorder>
    );
};

export default NotPermitted;
