import { encryptLocalData } from 'helpers/EncryptLocalStorage';
import { LoginModel } from 'models/auth/login';
import axios, { AxiosError } from 'axios';
import { REACT_APP_MIDDLEWARE_API } from 'lily-env';
import { AppThunk } from 'redux-state/store';
import sliceOAuth from './reducers';
import { openErrorGlobalSnackbar } from 'redux-state/globalSnackbar/actions';
// Function for making post request to /auth/user/login

export const login = (data: LoginModel, credential: string): AppThunk => {
    const Authorization = `Bearer ${credential}`;
    const headers = { Authorization, 'Content-Type': 'application/json' };

    const signInType = data?.type;

    encryptLocalData('signInType', signInType);

    let url = `${REACT_APP_MIDDLEWARE_API}/auth/b2c/login`;

    if (signInType === 'azure') {
        url = `${REACT_APP_MIDDLEWARE_API}/auth/microsoft/login`;
    }

    return async (dispatch): Promise<void> =>
        axios
            .get(url, { headers })
            .then((response) => {
                dispatch(sliceOAuth.actions.loginSuccess(response.data));
            })
            .catch((error: AxiosError) => {
                dispatch(sliceOAuth.actions.loginFailed());
                console.error(error);
                //display error message
                dispatch(openErrorGlobalSnackbar({ title: 'Login Failed', message: `${JSON.stringify(error.response?.data)}` }));
            });
};

export const loginRequested =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(sliceOAuth.actions.loginRequested());
    };

export const resetLoginState =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(sliceOAuth.actions.resetLogin());
    };

export const logout =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(sliceOAuth.actions.logout());
    };
