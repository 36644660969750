import { useEffect, useState } from 'react';
import PageLoader from 'components/common/PageLoader';
import { msalInstance } from 'helpers/azureLogin';
import { msalB2CInstance } from 'helpers/b2cLogin';
import { MsalProvider } from '@azure/msal-react';
import { useLocation, useNavigate } from 'react-router';
import { isEmpty, size } from 'lodash';
import { useDispatch } from 'redux-state/store';

import { openErrorGlobalSnackbar, openInfoGlobalSnackbar } from 'redux-state/globalSnackbar/actions';
import { AccountInfo } from '@azure/msal-browser';
import { lilyJwt } from 'helpers/ParseJWT';

const MSALDecider = ({ children }: any) => {
    const [isLoadingMSAL, setIsLoadingMSAL] = useState(true);
    const [isB2C, setIsB2C] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const currentLocation = location.pathname;
    const [hasNoAccounts, setHasNoAccounts] = useState(true);

    const userName = localStorage.getItem('activeUser');

    useEffect(() => {
        Promise.all([msalInstance.handleRedirectPromise(), msalB2CInstance.handleRedirectPromise()])
            .then((loginResponses) => {
                if (!userName) {
                    navigate('/login');
                    return;
                }
                setIsLoadingMSAL(false);
                //initial login checks
                let foundInternalAccountMatch: AccountInfo | null = loginResponses[0]?.account || null;
                let foundB2CAccountMatch: AccountInfo | null = loginResponses[1]?.account || null;

                if (size(foundInternalAccountMatch) > 0 && size(foundB2CAccountMatch) > 0) {
                    //checks for reload
                    foundInternalAccountMatch = msalInstance.getActiveAccount();
                    foundB2CAccountMatch = msalB2CInstance.getActiveAccount();
                }

                const activeAccount = size(msalB2CInstance.getActiveAccount()) > 0 || size(msalInstance.getActiveAccount()) > 0;

                if (userName && !foundInternalAccountMatch && !foundB2CAccountMatch && !activeAccount) {
                    navigate('/login');
                    return;
                }

                if (foundB2CAccountMatch) {
                    msalB2CInstance.setActiveAccount(foundB2CAccountMatch);
                    setIsB2C(true);
                    setHasNoAccounts(false);
                    return;
                }

                if (foundInternalAccountMatch) {
                    msalInstance.setActiveAccount(foundInternalAccountMatch);
                    setHasNoAccounts(false);
                    return;
                }

                if (activeAccount) {
                    setHasNoAccounts(false);
                    return;
                }

                navigate('/login');
            })
            .catch((e) => {
                console.warn('Error happened during handleRedirectPromise:', e);
                if (currentLocation !== '/login') {
                    navigate('/login');
                }
                const userCancelled: boolean = e?.message.includes('AADB2C90091');
                if (!userCancelled) {
                    dispatch(openErrorGlobalSnackbar({ title: 'Login Error', message: `${e?.message}` }));
                } else {
                    // Set cancelError flag in localStorage to display error message
                    localStorage.setItem('cancelError', 'true');
                }
            });
    }, []);

    return isLoadingMSAL || hasNoAccounts || !userName ? (
        <PageLoader />
    ) : isB2C ? (
        <MsalProvider instance={msalB2CInstance}>{children}</MsalProvider>
    ) : (
        <MsalProvider instance={msalInstance}>{children}</MsalProvider>
    );
};

export default MSALDecider;
