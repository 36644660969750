import { cloneDeep, isEmpty, isEqual, isEqualWith, size } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAMPayload, SubmitSubsidiaryTags } from 'models/PAM/PAM';

import { convergeTagsandSubsidiaryTags } from 'models/PAM/TypeConverters';
import { Filters } from 'models/PAM/Filters';
import { DEFAULT_PAGE_SIZE } from 'components/common/CatalogManagers/SearchControls/SearchConstants/defaults';
import { ProductCopyBatch } from 'models/ProductCopy/Batches';
import { ProductCopyRedux } from 'models/ProductCopy/ProductCopy';
import { ProductCopyResponseTypeProduct, ProductCopyStatus } from 'models/ProductCopy/ProductCopyResponseType';
import { testAndDecodeBase64ToString } from 'helpers/encodingHelpers';

//pageSize only for card view

const initialState: ProductCopyRedux = {
    batchPageNumber: 1,
    currentProduct: undefined,
    loadedProducts: [],
    order: '',
    originalDescription: '',
    pageNumber: 1,
    pageSize: 100,
    payload: {},
    personTypes: [],
    prePayload: {},
    productBatches: [],
    productTypes: [],
    resetDescription: 0,
    checkedList: [],
    searchTag: undefined,
    selectedIndex: 0,
    status: [],
    subsidiaryTags: [],
    totalNoOfBatches: 0,
    totalNoOfProducts: 0,
};

const sliceProductCopy = createSlice({
    name: 'productCopy',
    initialState,
    reducers: {
        initalizeSearch(state: ProductCopyRedux): void {
            state.subsidiaryTags = [];
            state.productTypes = [];
            state.loadedProducts = [];
            state.totalNoOfProducts = 0;
            state.pageNumber = 1;
            state.pageSize = DEFAULT_PAGE_SIZE;
        },

        //basic functions to update state
        setSearchTag(state: ProductCopyRedux, action: PayloadAction<SubmitSubsidiaryTags>): void {
            state.searchTag = action.payload.category === '' ? undefined : action.payload;
        },
        setStatusFilter(state: ProductCopyRedux, action: PayloadAction<ProductCopyStatus>): void {
            const clone = cloneDeep(state.status);
            const duplicate = clone.includes(action.payload);
            if (duplicate) {
                const index = clone.indexOf(action.payload);
                clone.splice(index, 1);
            } else {
                clone.push(action.payload);
            }
            state.status = clone;
        },
        clearProductCopyStatusFilter(state: ProductCopyRedux): void {
            state.status = [];
        },
        setPageNumber(state: ProductCopyRedux, action: PayloadAction<number>): void {
            state.pageNumber = action.payload;
        },
        setPageSize(state: ProductCopyRedux, action: PayloadAction<number>): void {
            state.pageSize = action.payload;
        },
        increasePageNumber(state: ProductCopyRedux): void {
            state.pageNumber++;
        },
        increaseBatchPageNumber(state: ProductCopyRedux): void {
            state.batchPageNumber++;
        },
        setTotalNoOfProducts(state: ProductCopyRedux, action: PayloadAction<number>): void {
            state.totalNoOfProducts = action.payload;
        },
        setTotalNoOfBatches(state: ProductCopyRedux, action: PayloadAction<number>): void {
            state.totalNoOfBatches = action.payload;
        },
        setLoadedProducts(state: ProductCopyRedux, action: PayloadAction<ProductCopyResponseTypeProduct[]>): void {
            //prevent double load
            if (state.pageNumber === 1) {
                state.loadedProducts = [];
            }
            state.loadedProducts = [...state.loadedProducts].concat(action.payload);
        },
        setSelectedIndex(state: ProductCopyRedux, action: PayloadAction<number>): void {
            state.selectedIndex = action.payload;
            state.currentProduct = state.loadedProducts[action.payload];
            const decodedDescription = testAndDecodeBase64ToString(state.loadedProducts[action.payload]?.description || '');
            state.originalDescription = `<div style="white-space: pre-line">${decodedDescription}</div>`;
        },
        setCurrentProduct(state: ProductCopyRedux, action: PayloadAction<{ newProduct?: ProductCopyResponseTypeProduct }>): void {
            state.currentProduct = action.payload.newProduct;
            const decodedDescription = testAndDecodeBase64ToString(action.payload.newProduct?.description || '');
            state.originalDescription = `<div style="white-space: pre-line">${decodedDescription}</div>`;
        },
        resetEditableDescription(state: ProductCopyRedux): void {
            const increase = state.resetDescription + 1;
            state.resetDescription = increase;
        },
        setProductBatches(state: ProductCopyRedux, action: PayloadAction<ProductCopyBatch[]>): void {
            state.productBatches = [...state.productBatches].concat(action.payload);
        },
        resetLoadedProducts(state: ProductCopyRedux): void {
            state.loadedProducts = [];
        },
        //prePayload preps the call without triggering a fetch
        setPreSearchPayload(state: ProductCopyRedux) {
            const orangizedSubsidiaryTags =
                size(state.subsidiaryTags) > 0 || size(state.searchTag) > 0
                    ? convergeTagsandSubsidiaryTags(state.subsidiaryTags, state.searchTag)
                    : undefined;

            let payload = new PAMPayload();

            payload = {
                person_types: isEmpty(state.personTypes) ? undefined : state.personTypes.map((x) => x.tag),
                product_types: isEmpty(state.productTypes) ? undefined : state.productTypes.map((x) => x.tag),
                subsidiary_tags: isEmpty(orangizedSubsidiaryTags) ? undefined : orangizedSubsidiaryTags,
                status: isEmpty(state.status) ? undefined : state.status,
            };
            state.prePayload = payload;
        },

        setAllAppliedTags(state: ProductCopyRedux, action: PayloadAction<Filters>): void {
            const { person, product, subsidiary } = action.payload;
            //logic happens in filters component
            state.subsidiaryTags = subsidiary;
            state.productTypes = product;
            state.personTypes = person;
        },
        removeAppliedTag(state: ProductCopyRedux, action: PayloadAction<Filters>): void {
            const { person, product, subsidiary } = action.payload;
            if (size(person) > 0) {
                state.personTypes = state.personTypes.filter((filter) => !isEqual(person[0], filter));
            }
            if (size(subsidiary) > 0) {
                state.subsidiaryTags = state.subsidiaryTags.filter((filter) => !isEqual(subsidiary[0], filter));
            }
            if (size(product) > 0) {
                state.productTypes = state.productTypes.filter((filter) => !isEqual(product[0], filter));
            }
        },
        updateStatus(state: ProductCopyRedux, action: PayloadAction<{ productIds: number[]; newStatus: ProductCopyStatus }>): void {
            const { newStatus, productIds } = action.payload;
            state.loadedProducts = state.loadedProducts.map((product) =>
                productIds.includes(product.productId) ? { ...product, status: newStatus } : product
            );
            if (state.currentProduct) {
                state.currentProduct.status = newStatus;
            }
        },
        updateTitle(state: ProductCopyRedux, action: PayloadAction<{ productId: number; newTitle: string }>): void {
            const { newTitle, productId } = action.payload;
            state.loadedProducts = state.loadedProducts.map((product) =>
                product.productId === productId ? { ...product, title: newTitle } : product
            );
            if (state.currentProduct) {
                state.currentProduct.title = newTitle;
            }
        },
        updateDescription(state: ProductCopyRedux, action: PayloadAction<{ productId: number; newDescription: string }>): void {
            const { newDescription, productId } = action.payload;
            state.loadedProducts = state.loadedProducts.map((product) =>
                product.productId === productId ? { ...product, description: newDescription } : product
            );
            state.originalDescription = newDescription;

            if (state.currentProduct) {
                state.currentProduct.description = newDescription;
            }
        },

        updateCheckedList(state: ProductCopyRedux, action: PayloadAction<{ checkedList: number[] }>): void {
            const { checkedList } = action.payload;
            state.checkedList = [...checkedList];
        },

        //clear search bar for new search
        clearSearchTag(state: ProductCopyRedux) {
            state.searchTag = undefined;
        },

        //updating the payload will trigger a fetch
        handleSubmit(state: ProductCopyRedux) {
            const clonePrePayload = cloneDeep(state.prePayload);
            state.loadedProducts = [];
            state.totalNoOfProducts = 0;
            state.pageNumber = 1;
            state.pageSize = DEFAULT_PAGE_SIZE;
            state.payload = clonePrePayload;
        },

        setOrder(state: ProductCopyRedux, action: PayloadAction<string>): void {
            state.order = action.payload;
        },
    },
});

export const { reducer } = sliceProductCopy;

export default sliceProductCopy;
