export enum ACTION_TYPES {
    UPDATE_PRODUCT_DETAILS_TAGS_REQUEST = 'productDetails/updateTagsRequest',
    UPDATE_PRODUCT_DETAILS_TAGS_SUCCESS = 'productDetails/updateTagsSuccess',
    UPDATE_PRODUCT_DETAILS_TAGS_FAILURE = 'productDetails/updateTagsFailure',
    UPDATE_TAGS_FLUSH = 'productDetails/updateTagsFlush',
}

export enum UPDATE_TAG_RESPONSE_INFOS {
    ADDED_FOR_ALL_VARIANTS = 'Tag added successfully! Your changes will affect all product variants',
    ADDED_FOR_CURRENT_VARIANT = 'Tag added successfully! Your changes will affect this product variant only',
    REMOVED_FOR_ALL_VARIANTS = 'Tag removed successfully! Your changes will affect all product variants',
    REMOVED_FOR_CURRENT_VARIANT = 'Tag removed successfully! Your changes will affect this product variant only',
    FAILED_TO_SAVE = 'We could not save your changes. Try again please.',
}
