import Cookies from 'js-cookie';

export const CONFIG_REQUESTED = 'search/CONFIG_REQUESTED';
export const CONFIG_SUCCESS = 'search/CONFIG_SUCCESS';
export const CONFIG_FAILURE = 'search/CONFIG_FAILURE';
export const SEARCH_REQUESTED = 'search/SEARCH_REQUESTED';
export const SEARCH_SUCCESS = 'search/SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'search/SEARCH_FAILURE';
export const BRAND_REQUESTED = 'search/BRAND_REQUESTED';
export const BRAND_SUCCESS = 'search/BRAND_SUCCESS';
export const BRAND_FAILURE = 'search/BRAND_FAILURE';

const initialState = {
    loading: false,
    searchLoading: false,
    loaded: false,
    error: false,
    success: false,
    searchData: [],
    configData: [],
    brandData: [],
};

//I have no idea why removing this breaks everything

export default (state = initialState, action) => {
    switch (action.type) {
        case BRAND_REQUESTED: {
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        }
        case BRAND_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                loaded: true,
                brandData: action.result?.data,
            };
        }
        case BRAND_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
                loaded: true,
            };
        }
        case SEARCH_REQUESTED: {
            return {
                ...state,
                searchLoading: true,
                loaded: false,
            };
        }
        case SEARCH_SUCCESS: {
            return {
                ...state,
                searchLoading: false,
                error: false,
                loaded: true,
                success: true,
                searchData: action.result?.data?.products,
            };
        }
        case SEARCH_FAILURE: {
            return {
                ...state,
                searchLoading: false,
                error: true,
                loaded: true,
            };
        }
        case CONFIG_REQUESTED: {
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        }
        case CONFIG_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                loaded: true,
                configData: action.result?.data,
            };
        }
        case CONFIG_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
                loaded: true,
            };
        }

        default:
            return state;
    }
};
