import { SvgIcon, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/types';

const ProductCopyIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> = () => (
    <SvgIcon>
        <svg fill="none" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4a4 4 0 0 1 4-4h18a4 4 0 0 1 4 4v18a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4z" fill="#FFF5F2" />
            <path
                d="M10.294 18c-.485 0-.9-.147-1.246-.44-.346-.294-.519-.647-.519-1.06v-9c0-.412.173-.766.52-1.06A1.86 1.86 0 0 1 10.293 6h7.941c.486 0 .901.147 1.247.44.345.294.518.647.518 1.06v9c0 .413-.173.766-.518 1.06-.346.293-.761.44-1.247.44h-7.94zm-3.53 3c-.485 0-.9-.147-1.246-.44C5.173 20.266 5 19.913 5 19.5V9.75c0-.213.085-.39.254-.535A.936.936 0 0 1 5.882 9c.25 0 .46.072.63.215a.676.676 0 0 1 .253.535v9.75h8.823c.25 0 .46.072.63.216a.673.673 0 0 1 .253.534c0 .212-.085.39-.254.534a.938.938 0 0 1-.629.216H6.765z"
                fill="#FEB7A5"
            />
        </svg>
    </SvgIcon>
);
export default ProductCopyIcon;
