import BaseModal from 'wrappers/BaseModal';
import { Modal } from 'models/modals';

const LocalStagingApiWarnModal = ({ modal }: { modal: Modal }) => (
    <BaseModal
        description="Your local app is currently running on staging, which means that the request you are trying to perform will affect the production. Are you sure?"
        header="Risky operation detected!"
        id={modal.id}
        onCancel={modal?.props?.onReject}
        onConfirm={modal?.props?.onSubmit}
    />
);

export default LocalStagingApiWarnModal;
