import { makeStyles } from '@mui/styles';

const NavbarStyles = makeStyles(() => ({
    dropDownButton: {
        background: '#FFFFFF',
        borderRadius: '6px',
        color: '#3c4043',
        fontFamily: 'proxima-nova',
        fontSize: '17px',
        textAlign: 'center',
        height: '38px',
        justifyContent: 'flex-start',
        paddingLeft: '15px',
        paddingRight: '15px',
        textTransform: 'none',
    },
    dropDownItem: {
        fontFamily: 'proxima-nova',
        fontSize: '10px',
        fontWeight: '400',
        lineHeight: '21px',
        letterSpacing: '-0.17599999904632568px',
        textAlign: 'left',
        width: '200px',
        height: '38px',
    },
    currentDropItem: {
        fontFamily: 'proxima-nova',
        fontSize: '10px',
        fontWeight: '400',
        lineHeight: '21px',
        letterSpacing: '-0.17599999904632568px',
        textAlign: 'left',
        width: '200px',
        border: '1px solid #0000000A',
        background: '#FFF5F2',
        height: '38px',
    },
    blinkingDot: {
        animation: `$blink 2000ms infinite`,
    },
    '@keyframes blink': {
        '0%': { transform: 'scale(1)' },
        '50%': { transform: 'scale(1.5)' },
    },
}));

export default NavbarStyles;
