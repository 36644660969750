/* istanbul ignore file */

import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import {
    REACT_APP_AZURE_INTERNAL_CLIENT_ID,
    REACT_APP_AZURE_INTERNAL_TENANT_ID,
    REACT_APP_AZURE_INTERNAL_READ,
    REACT_APP_AZURE_INTERNAL_APPLICATION_ID_URI,
} from 'lily-env';

const msalConfig = {
    auth: {
        clientId: REACT_APP_AZURE_INTERNAL_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${REACT_APP_AZURE_INTERNAL_TENANT_ID}`,
        redirectUri: '/',
        postLogoutRedirectUri: '/login',
        clientCapabilities: ['CP1'],
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: true,
        secureCookies: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                }
            },
        },
    },
};

export const azureProtectedResources = {
    scopes: {
        read: [`${REACT_APP_AZURE_INTERNAL_APPLICATION_ID_URI}/${REACT_APP_AZURE_INTERNAL_READ}`],
    },
};

export const azureLoginRequest = {
    scopes: [...azureProtectedResources.scopes.read],
    redirectUri: '/',
};

export const msalInstance = new PublicClientApplication(msalConfig);
