import * as React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const LogoutIcon = createSvgIcon(
    <path
        d="M9.104 3.41699L2.042 10.5C1.84734 10.6947 1.61467 10.792 1.344 10.792C1.07334 10.792 0.84067 10.6947 0.646004 10.5C0.451337 10.3053 0.354004 10.0727 0.354004 9.80199C0.354004 9.53133 0.451337 9.29866 0.646004 9.10399L7.729 2.04199H1.854C1.59 2.04199 1.35734 1.94133 1.156 1.73999C0.954671 1.53866 0.854004 1.30599 0.854004 1.04199C0.854004 0.777992 0.954671 0.545326 1.156 0.343992C1.35734 0.142659 1.59 0.0419922 1.854 0.0419922H10.104C10.368 0.0419922 10.6007 0.142659 10.802 0.343992C11.0033 0.545326 11.104 0.777992 11.104 1.04199V9.29199C11.104 9.55599 11.0033 9.78866 10.802 9.98999C10.6007 10.1913 10.368 10.292 10.104 10.292C9.84 10.292 9.60734 10.1913 9.406 9.98999C9.20467 9.78866 9.104 9.55599 9.104 9.29199V3.41699Z"
        fill="#1C1B1F"
    />,
    'Subsidiary'
);

export default LogoutIcon;
