import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import segmentPlugin from '@analytics/segment';
import { REACT_APP_SEGMENT_KEY } from 'lily-env';
import { TrackUserData } from 'models/analytics/TrackUser';

const analytics = Analytics({
    app: 'lily-app',
    plugins: [
        googleAnalytics({
            trackingId: 'UA-220985240-2',
            /* Map your Google Analytics custom dimensions here */
            customDimensions: {
                user_id: 'LoggedIn_User',
            },
        }),
        segmentPlugin({
            writeKey: `${REACT_APP_SEGMENT_KEY}`,
        }),
    ],
});

export const sendUserAnalytics = (trackData: TrackUserData, userID: number) =>
    Promise.race([
        new Promise((resolve) => setTimeout(resolve, 5000)),
        new Promise((resolve) => {
            analytics.identify(`lilyappPortalUserId-${userID}`, trackData, { timestamp: new Date().toISOString() }, () =>
                analytics.track('Signed In', trackData, { timestamp: new Date().toISOString() }, resolve)
            );
        }),
    ]);

export default analytics;
