import { GlobalSnackbarInput, GlobalSnackbarSeverity } from 'models/globalSnackbar/GlobalSnackbar';
import sliceGlobalSnackbar from './reducers';
import { AppThunk } from 'redux-state/store';

export const openSuccessGlobalSnackbar =
    ({ message, title = '' }: GlobalSnackbarInput): AppThunk =>
    async (dispatch): Promise<void> => {
        const severity = GlobalSnackbarSeverity.SUCCESS;
        dispatch(sliceGlobalSnackbar.actions.openSnackbar({ title, message, severity }));
    };

export const openErrorGlobalSnackbar =
    ({ message, title = '' }: GlobalSnackbarInput): AppThunk =>
    async (dispatch): Promise<void> => {
        const severity = GlobalSnackbarSeverity.ERROR;
        dispatch(sliceGlobalSnackbar.actions.openSnackbar({ title, message, severity }));
    };

export const openInfoGlobalSnackbar =
    ({ message, title = '' }: GlobalSnackbarInput): AppThunk =>
    async (dispatch): Promise<void> => {
        const severity = GlobalSnackbarSeverity.INFO;
        dispatch(sliceGlobalSnackbar.actions.openSnackbar({ title, message, severity }));
    };

export const openWarningGlobalSnackbar =
    ({ message, title = '' }: GlobalSnackbarInput): AppThunk =>
    async (dispatch): Promise<void> => {
        const severity = GlobalSnackbarSeverity.WARNING;
        dispatch(sliceGlobalSnackbar.actions.openSnackbar({ title, message, severity }));
    };

export const closeGlobalSnackbar =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(sliceGlobalSnackbar.actions.removeSnackbar());
    };
