import BaseModal from 'wrappers/BaseModal';
import { Modal } from 'models/modals';

const RetailerSwitchConfirmation = ({ modal }: { modal: Modal }) => (
    <BaseModal
        description="Your local app is currently running on staging, which means that production PI APIs are being used! Are you sure you want to switch a retailer?"
        header="You are going to switch a retailer!"
        id={modal.id}
        onCancel={modal?.props?.onReject}
        onConfirm={modal?.props?.onSubmit}
    />
);

export default RetailerSwitchConfirmation;
