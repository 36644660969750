import { createSlice } from '@reduxjs/toolkit';

import { IIntegration } from 'v2/models/redux/integration';

const initialState: IIntegration = {
    destination: '',
    source: 0,
    sourceData: {},
    storeInfo: {
        storeUrl: '',
        storeAdminToken: '',
    },
};

/**
 * @description Integration Manager Slice & reducer
 */
const integrationManagerSlice = createSlice({
    name: 'catalogManager',
    initialState,
    reducers: {
        setSource: (state: IIntegration, action): void => {
            state.source = action.payload;
        },
        setDestination: (state: IIntegration, action): void => {
            state.destination = action.payload;
        },
        setShopifyStoreURL: (state: IIntegration, action): void => {
            state.storeInfo.storeUrl = action.payload;
        },
        setShopifyStoreAccessToken: (state: IIntegration, action): void => {
            state.storeInfo.storeAdminToken = action.payload;
        },
        resetStoreInfo: (state: IIntegration): void => {
            state.storeInfo.storeUrl = '';
            state.storeInfo.storeAdminToken = '';
        },
    },
});

export default integrationManagerSlice;
