import ImagesGalleryModal from 'components/ImagesGalleryModal';
import LocalStagingApiWarnModal from 'components/LocalStagingApiWarnModal';
import LoginFailureModal from 'components/LoginFailureModal';
import RetailerSwitchConfirmation from 'components/RetailerSwitchConfirmation';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import ProductChangedModal from 'components/ProductChangedModal';
import { MODAL_TYPES } from 'constants/modalTypes';
import { getOpenedModals } from 'redux-state/modals';
import { useSelector } from 'redux-state/store';
import { Modal } from 'models/modals';

export const modalComponentCorrelations = {
    [MODAL_TYPES.LOCAL_STAGING_API_REQUEST_WARN]: LocalStagingApiWarnModal,
    [MODAL_TYPES.LOCAL_STAGING_RETAILER_SWITCH_WARN]: RetailerSwitchConfirmation,
    [MODAL_TYPES.LOGIN_FAILURE]: LoginFailureModal,
    [MODAL_TYPES.PRODUCT_IMAGES]: ImagesGalleryModal,
    [MODAL_TYPES.PRODUCT_COPY_UNSAVED_WARNING]: UnsavedChangesModal,
    [MODAL_TYPES.PRODUCT_CHANGED_MODAL]: ProductChangedModal,
};

const modalRenderer = (modal: Modal, index: Number) => {
    const Component = modalComponentCorrelations[modal.id];

    return <Component key={`${modal.id}_${index}`} modal={modal} />;
};

const ModalManagerWrapper = () => {
    const modals = useSelector(getOpenedModals);
    return <>{modals.map(modalRenderer)}</>;
};

export default ModalManagerWrapper;
