import { BrandsContext, Retailer } from 'models/brands/brands';
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { encryptLocalData, getEncyptedLocalData } from 'helpers/EncryptLocalStorage';
import { lilyJwt } from 'helpers/ParseJWT';

import { cloneDeep } from 'lodash';
import { JWT_TOKEN_STATUS, validateToken } from 'helpers/loginHelpers';
import { EvaluationContext, OpenFeature } from '@openfeature/web-sdk';

const initialBrands: BrandsContext = {
    currentRetailer: new Retailer(),
};

export const restoreSettings = () => {
    let brandsContext = null;

    try {
        const storedData = getEncyptedLocalData('brandsContext');

        if (storedData) {
            brandsContext = storedData as BrandsContext;
        } else {
            brandsContext = initialBrands;
        }
    } catch (err) {
        console.error(err);
        // If stored data is not a strigified JSON this will fail,
        // that's why we catch the error
    }

    return brandsContext;
};

export const storeSettings = (brandsContext: BrandsContext) => {
    encryptLocalData('brandsContext', brandsContext);
};
interface CreatBrandContext {
    brandsContext: BrandsContext;
    saveBrand: (updatedBrands: BrandsContext) => void;
}

const BrandContext = createContext<CreatBrandContext>({ brandsContext: initialBrands, saveBrand: () => {} });

interface TBrandProvider {
    children: ReactNode;
}

export function BrandProvider({ children }: TBrandProvider) {
    const [brandsContext, setBrandContext] = useState<BrandsContext>(initialBrands);
    const jwtStatus = validateToken();

    useEffect(() => {
        const restoreBrand = restoreSettings();

        if (restoreBrand !== null) {
            setBrandContext(restoreBrand);
        }

        if (jwtStatus === JWT_TOKEN_STATUS.VALID && restoreBrand === null) {
            const JWT = lilyJwt();
            const email: string = JWT?.sub;
            const userID: number = JWT?.id;
            const { firstName, lastName, role, userName } = JWT || {};
            setBrandContext({ currentRetailer: JWT?.brands[0] });
            const newContext: EvaluationContext = {
                targetingKey: `${userID}`,
                email,
                retailer: JWT?.brands[0].code,
                userName,
                userID: `${userID}`,
                role,
                lastName,
                firstName,
            };

            OpenFeature.setContext(newContext);
        }
    }, []);

    const saveBrand = useCallback((updatedBrands: BrandsContext) => {
        const clone = cloneDeep(updatedBrands);
        storeSettings(clone);
        setBrandContext(clone);
        const JWT = lilyJwt();
        const email: string = JWT?.sub;
        const userID: number = JWT?.id;
        const { firstName, lastName, role, userName } = JWT || {};
        const newContext: EvaluationContext = {
            targetingKey: `${userID}`,
            email,
            retailer: clone.currentRetailer.code,
            userName,
            userID: `${userID}`,
            role,
            lastName,
            firstName,
        };
        OpenFeature.setContext(newContext);
    }, []);

    return <BrandContext.Provider value={{ brandsContext, saveBrand }}>{children}</BrandContext.Provider>;
}

export default BrandContext;
