import { ProductCopyBatch } from 'models/ProductCopy/Batches';
import { PAMResponseTypeProducts } from './PAMResponseType';
import { ProductCopyStatus } from 'models/ProductCopy/ProductCopyResponseType';

export class PAMRedux {
    payload: PAMPayload = {};
    personTypes: Tag[] = [];
    prePayload: PAMPayload = {};
    productTypes: Tag[] = [];
    searchTag?: SubmitSubsidiaryTags = new SubmitSubsidiaryTags();
    subsidiaryTags: Tag[] = [];
    loadedProducts: PAMResponseTypeProducts[] = [];
    totalNoOfProducts: number = 0;
    totalNoOfBatches: number = 0;
    pageNumber: number = 1;
    pageSize: number = 20;
    order: string = '';
    batchPageNumber: number = 1;
    productBatches: ProductCopyBatch[] = [];
}

export class PAMPayload {
    brand?: string;
    keywords?: string[];
    person_types?: string[];
    product_types?: string[];
    sku_id?: string;
    subsidiary_tags?: SubmitSubsidiaryTags[];
    status?: ProductCopyStatus[];
}

export class SubsidiaryTags {
    category: string = '';
    tags: TagsWithSynonyms[] = [];
}

export class TagsWithSynonyms {
    tag: string = '';
    synonyms: string[] = [];
}

export class Tag {
    category: string = '';
    label?: string;
    tag: string = '';
}

export class SubmitSubsidiaryTags {
    category: string = '';
    tags: string[] = [];
}

export class AutoCompleteTag {
    category: string = '';
    name: string = '';
}
