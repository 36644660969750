import './wdyr';

import './index.scss';

import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { LaunchDarklyClientProvider } from '@openfeature/launchdarkly-client-provider';
import { OpenFeature } from '@openfeature/web-sdk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { REACT_APP_LAUNCH_DARKLY_CLIENT } from 'lily-env';
import LilyGlobalTheme from 'theme/theme';
import GlobalSnackbar from 'wrappers/GlobalSnackbarWrapper';
import Router from './AllRoutes/Routes';
import store from './redux-state/store';

const target = document.querySelector('#root');

const ldOptions = {
    streaming: true,
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            retry: false,
            //while fetching data, assume it is any empty array
            placeholderData: [],
            //by default data is fresh for 60 seconds
            staleTime: 60 * 1000,
            enabled: true,
            //data held as long as it is fresh
            cacheTime: Infinity,
        },
    },
});

const ldClient: string = REACT_APP_LAUNCH_DARKLY_CLIENT;

const ldOpenFeatureProvider = new LaunchDarklyClientProvider(ldClient, ldOptions);
OpenFeature.setProvider(ldOpenFeatureProvider);

(() => {
    render(
        <HelmetProvider>
            <Provider store={store}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={LilyGlobalTheme}>
                        <CssBaseline />
                        <GlobalSnackbar />
                        <QueryClientProvider client={queryClient}>
                            <RouterProvider router={Router} />
                            <ReactQueryDevtools />
                        </QueryClientProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </Provider>
        </HelmetProvider>,
        target
    );
})();
