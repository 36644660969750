import { forEach, get, indexOf, isEmpty, size, sortBy } from 'lodash';
import { SubmitSubsidiaryTags, SubsidiaryTags, Tag } from './PAM';

import AutocompletePAM from './Autocomplete';
import { PairedSubsidiaryTags } from './ProductDetail';
import { Filters, FiltersRedux } from './Filters';

//intended to take in multple types of arrays of tags and return a PairedSubsidiaryTags
export const convertToSTobj = (arrayOfTags: SubsidiaryTags[] = []): PairedSubsidiaryTags =>
    arrayOfTags.reduce((result, item: SubsidiaryTags) => {
        const { category, tags } = item;
        return {
            ...result,
            [category]: tags.map((name) => name.tag),
        };
    }, {});

export const convertPSTToAutocompletePAM = (subsidiaryTags: PairedSubsidiaryTags): AutocompletePAM[] => {
    const newTags: AutocompletePAM[] = [];

    forEach(subsidiaryTags, (tags: string[], category: string) => {
        tags.forEach((tag) => {
            newTags.push({ name: tag, category });
        });
    });

    return newTags;
};

export const alphabeticStringSort = (a: string, b: string) => {
    const str1 = a.toLowerCase();
    const str2 = b.toLowerCase();
    if (str1 < str2) {
        return -1;
    }
    if (str1 > str2) {
        return 1;
    }
    return 0;
};

export const convergeTagsandSubsidiaryTags = (
    tags: Tag[],
    search: SubmitSubsidiaryTags = new SubmitSubsidiaryTags()
): SubmitSubsidiaryTags[] | undefined => {
    const newTags: SubmitSubsidiaryTags[] = isEmpty(search) ? [] : [search];

    tags.forEach(({ category, label, tag }: Tag) => {
        const duplicate = newTags.filter((x: SubmitSubsidiaryTags) => x.category === category);
        if (size(duplicate) > 0) {
            const index = indexOf(newTags, duplicate[0]);
            newTags[index].tags.push(tag);
        } else {
            newTags.push({ category, tags: [tag] });
        }
    });

    if (size(newTags) <= 0) {
        return;
    }

    const sortedNewTags = sortBy(
        newTags.map((newTag) => ({
            category: newTag.category,
            tags: newTag.tags.sort(alphabeticStringSort),
        })),
        [
            'category',
            ({ tags }) => {
                const tagsStr = JSON.stringify(tags);
                return tagsStr;
            },
        ]
    );

    return sortedNewTags;
};

export const convertFiltersReduxArrayToFilters = (filters: FiltersRedux[]): Filters => {
    const newFilters = new Filters();

    Object.keys(filters).forEach((filter) => {
        const itemKey = Object.keys(filters[filter as keyof FiltersRedux[]]);
        const tagType: string = itemKey[0];
        const oldFormat: FiltersRedux = filters[filter as keyof FiltersRedux[]] as FiltersRedux;
        newFilters[tagType as keyof Filters].push({
            category: oldFormat[tagType].category,
            label: oldFormat[tagType].label,
            tag: oldFormat[tagType].tag,
        });
    });
    return newFilters;
};

export const convertFiltersReduxToFilters = (filter: FiltersRedux): Filters => {
    const newFilters = new Filters();

    const itemKey = Object.keys(filter);
    const tagType: string = itemKey[0];
    newFilters[tagType as keyof Filters].push({ category: filter[tagType].category, label: filter[tagType].label, tag: filter[tagType].tag });

    return newFilters;
};
