import { cloneDeep, isEmpty, isEqual, size } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAMPayload, PAMRedux, SubmitSubsidiaryTags } from 'models/PAM/PAM';

import { convergeTagsandSubsidiaryTags } from 'models/PAM/TypeConverters';
import { Filters } from 'models/PAM/Filters';
import { PAMResponseTypeProducts } from 'models/PAM/PAMResponseType';
import { DEFAULT_PAGE_SIZE } from 'components/common/CatalogManagers/SearchControls/SearchConstants/defaults';
import { ProductCopyBatch } from 'models/ProductCopy/Batches';
import { ProductCopyStatus } from 'models/ProductCopy/ProductCopyResponseType';

//pageSize only for card view

const initialState: PAMRedux = {
    payload: {},
    personTypes: [],
    prePayload: {},
    productTypes: [],
    searchTag: undefined,
    subsidiaryTags: [],
    loadedProducts: [],
    totalNoOfProducts: 0,
    totalNoOfBatches: 0,
    pageNumber: 1,
    batchPageNumber: 1,
    order: '',
    pageSize: 100,
    productBatches: [],
};

const slicePAM = createSlice({
    name: 'PAM',
    initialState,
    reducers: {
        initalizeSearch(state: PAMRedux): void {
            state.subsidiaryTags = [];
            state.productTypes = [];
            state.loadedProducts = [];
            state.totalNoOfProducts = 0;
            state.pageNumber = 1;
            state.pageSize = DEFAULT_PAGE_SIZE;
        },

        //basic functions to update state
        setSearchTag(state: PAMRedux, action: PayloadAction<SubmitSubsidiaryTags>): void {
            state.searchTag = action.payload.category === '' ? undefined : action.payload;
        },
        setPageNumber(state: PAMRedux, action: PayloadAction<number>): void {
            state.pageNumber = action.payload;
        },
        setPageSize(state: PAMRedux, action: PayloadAction<number>): void {
            state.pageSize = action.payload;
        },
        increasePageNumber(state: PAMRedux): void {
            state.pageNumber++;
        },
        increaseBatchPageNumber(state: PAMRedux): void {
            state.batchPageNumber++;
        },
        setTotalNoOfProducts(state: PAMRedux, action: PayloadAction<number>): void {
            state.totalNoOfProducts = action.payload;
        },
        setTotalNoOfBatches(state: PAMRedux, action: PayloadAction<number>): void {
            state.totalNoOfBatches = action.payload;
        },

        setLoadedProducts(state: PAMRedux, action: PayloadAction<PAMResponseTypeProducts[]>): void {
            state.loadedProducts = [...state.loadedProducts].concat(action.payload);
        },
        setProductBatches(state: PAMRedux, action: PayloadAction<ProductCopyBatch[]>): void {
            state.productBatches = [...state.productBatches].concat(action.payload);
        },
        resetLoadedProducts(state: PAMRedux): void {
            state.loadedProducts = [];
        },
        //prePayload preps the call without triggering a fetch
        setPreSearchPayload(state: PAMRedux) {
            const orangizedSubsidiaryTags =
                size(state.subsidiaryTags) > 0 || size(state.searchTag) > 0
                    ? convergeTagsandSubsidiaryTags(state.subsidiaryTags, state.searchTag)
                    : undefined;

            let payload = new PAMPayload();

            payload = {
                person_types: isEmpty(state.personTypes) ? undefined : state.personTypes.map((x) => x.tag),
                product_types: isEmpty(state.productTypes) ? undefined : state.productTypes.map((x) => x.tag),
                subsidiary_tags: isEmpty(orangizedSubsidiaryTags) ? undefined : orangizedSubsidiaryTags,
            };

            state.prePayload = payload;
        },

        setAllAppliedTags(state: PAMRedux, action: PayloadAction<Filters>): void {
            const { person, product, subsidiary } = action.payload;
            //logic happens in filters component
            state.subsidiaryTags = subsidiary;
            state.productTypes = product;
            state.personTypes = person;
        },
        removeAppliedTag(state: PAMRedux, action: PayloadAction<Filters>): void {
            const { person, product, subsidiary } = action.payload;
            if (size(person) > 0) {
                state.personTypes = state.personTypes.filter((filter) => !isEqual(person[0], filter));
            }
            if (size(subsidiary) > 0) {
                state.subsidiaryTags = state.subsidiaryTags.filter((filter) => !isEqual(subsidiary[0], filter));
            }
            if (size(product) > 0) {
                state.productTypes = state.productTypes.filter((filter) => !isEqual(product[0], filter));
            }
        },
        setApprovalStatus(state: PAMRedux, action: PayloadAction<{ productIds: number[]; newStatus: ProductCopyStatus }>): void {
            const { newStatus, productIds } = action.payload;
            state.loadedProducts = state.loadedProducts.map((product) =>
                productIds.includes(product.productId) ? { ...product, status: newStatus } : product
            );
            // TODO: Update the status on the back-end by API call
        },

        //clear search bar for new search
        clearSearchTag(state: PAMRedux) {
            state.searchTag = undefined;
        },

        //updating the payload will trigger a fetch
        handleSubmit(state: PAMRedux) {
            const clonePrePayload = cloneDeep(state.prePayload);
            state.loadedProducts = [];
            state.totalNoOfProducts = 0;
            state.pageNumber = 1;
            state.pageSize = DEFAULT_PAGE_SIZE;
            state.payload = clonePrePayload;
        },

        setOrder(state: PAMRedux, action: PayloadAction<string>): void {
            state.order = action.payload;
        },
    },
});

export const { reducer } = slicePAM;

export default slicePAM;
