import { Box, Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { AllupdatedQueryProducts } from 'models/proxyProduct/ProxyProduct';
import { getEncyptedLocalData } from './helpers/EncryptLocalStorage';

const copyTextToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let ok = false;
    try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
        ok = successful;
    } catch (err) {
        console.log('Error copying text', { err });
    }

    document.body.removeChild(textArea);
    return ok;
};

const getLocalStorageBackup = () => {
    const backup = {};
    for (let i = 0, len = localStorage.length; i < len; ++i) {
        backup[localStorage.key(i) || ''] = localStorage[localStorage.key(i) || ''];
    }
    return backup;
};

const backupLocalStorageToClipboard = () => {
    const ok = copyTextToClipboard(JSON.stringify(getLocalStorageBackup()));
    if (!ok) {
        alert('Error happened getting the backup');
    } else {
        alert('Backup copied to clipboard.');
    }
};

export const ProxyProductDebugTool = () => {
    const [show, setShow] = useState(false);
    const [count, setCount] = useState(0);
    const [batchKeys, setBatchKeys] = useState<string[]>([]);
    const showRef = useRef(show);
    const [text, setText] = useState('');
    const [showRestore, setShowRestore] = useState(false);

    const handleKey = ({ altKey, code, ctrlKey, shiftKey }: KeyboardEvent) => {
        if (altKey && shiftKey && ctrlKey && code === 'KeyD') {
            setShow((value) => {
                showRef.current = !value;
                return !value;
            });
        }
    };

    const registerToggle = () => {
        if (typeof document === 'undefined') return;
        document.addEventListener('keypress', handleKey);
        return () => {
            if (typeof document === 'undefined') return;
            document.removeEventListener('keypress', handleKey);
        };
    };

    const toggleRestore = () => {
        setShowRestore((prevState) => !prevState);
    };

    useEffect(() => {
        registerToggle();
        const interval = setInterval(() => {
            setCount((prevState) => prevState + 1);
            const newKeys: string[] = [];
            for (let i = 0, len = localStorage.length; i < len; ++i) {
                if (localStorage.key(i)?.startsWith('viewed_batch')) {
                    newKeys.push(localStorage.key(i) as string);
                }
            }
            setBatchKeys(newKeys);
        }, 500);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const onRestoreClick = () => {
        try {
            const backup = JSON.parse(text);
            console.log('Will restore', backup);
            Object.keys(backup).forEach((key) => {
                // Only restore keys that have the "batch" string in the name.
                if (!key.includes('batch')) {
                    return;
                }
                const value = backup[key];
                console.log('Will restore local storage key', { key, value });
                localStorage.setItem(key, value);
            });
            alert('Restore completed');
            window.location.reload();
        } catch (error: any) {
            alert(`Cannot restore: ${error.message}`);
        }
    };

    if (!show) return null;

    return (
        <Box
            sx={{
                position: 'fixed',
                zIndex: 2_000,
                maxHeight: '50vh',
                overflowY: 'auto',
                border: '1px solid #616161',
                borderRadius: '8px',
                // pointerEvents: 'none',
            }}
        >
            <Box
                sx={{
                    background: '#e03568',
                    color: 'white',
                    textAlign: 'center',
                }}
            >
                Proxy Tool Debugger
            </Box>
            <Box
                sx={{
                    background: '#ddd',
                }}
            >
                Press <code>alt+shift+ctrl+D</code> or <code>option+shift+ctrl+D</code> to toggle.
            </Box>
            <Box
                sx={{
                    background: '#fff',
                }}
            >
                <Button onClick={backupLocalStorageToClipboard} variant="outlined">
                    Backup
                </Button>
                <Button onClick={toggleRestore} variant="outlined">
                    Begin restoration
                </Button>
            </Box>
            <Box
                sx={{
                    background: '#fff',
                    opacity: 0.8,
                }}
            >
                {batchKeys.map((key) => (
                    <div key={key}>
                        <strong>{key}</strong>
                        <BatchData data={getEncyptedLocalData(key)} />
                    </div>
                ))}
            </Box>
            <Box
                sx={{
                    background: 'rgba(224,53,104,1)',
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: '200px',
                    display: showRestore ? 'flex' : 'none',
                    padding: 1,
                }}
            >
                <textarea
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Paste here the backup code. Then click the 'Restore' button."
                    style={{ flex: 1 }}
                    value={text}
                />
                <Button onClick={onRestoreClick} sx={{ ml: 1, background: 'white', '&:hover': { background: 'white' } }} variant="outlined">
                    Restore
                </Button>
            </Box>
        </Box>
    );
};

const BatchData = ({ data }: { data: { viewed: string[]; allupdatedQueryProducts: AllupdatedQueryProducts } }) => (
    <div>
        <div>Viewed: {JSON.stringify(data?.viewed?.length)}</div>
        <div>allupdatedQueryProducts.batchID: {JSON.stringify(data?.allupdatedQueryProducts?.batchID)}</div>
        <div>allupdatedQueryProducts.batch.length: {JSON.stringify(data?.allupdatedQueryProducts?.batch?.length)}</div>
        <br />
    </div>
);
