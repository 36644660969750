import { makeStyles } from '@mui/styles';

const LoginBorderStyles = makeStyles(() => ({
    wholeScreenCont: {
        height: '100%',
        width: '100%',
        maxWidth: 'unset',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
        padding: '20px',
    },
}));

export default LoginBorderStyles;
